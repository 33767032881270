<template>
  <div class="box-wrapper">
    <a
      class="box-delete"
      v-on:click="removeMyself"
      v-if="bActive && !disabled"
      :style="{
        top: bTop - 15 + 'px',
        left: bLeft + bWidth - 15 + 'px',
      }"
      v-bind:class="{ enabled: !disabled }"
    >
      <v-avatar height="30" width="30" min-width="30" color="white">
        <v-icon color="primary">mdi-delete</v-icon>
      </v-avatar>
    </a>
    <div
      class="label"
      v-if="bLabel && !tracking"
      :style="{
        top: bTop - 35 + 'px',
        left: bLeft + 'px',
        width: bWidth + 'px',
        minWidth: '60px',
      }"
      @click="selectBox"
    >
      <p style="font-size: 12pt; margin-left: 10px" class="mb-0 pb-0">
        {{ bLabel }}
      </p>
      <!-- <input
        type="text"
        :disabled="disabled"
        :placeholder="bLabel"
        :value="bLabel"
        @change="$emit('updated', $event.target.value, bIndex)"
      /> -->
    </div>

    <div
      class="box"
      :style="{
        top: bTop + 'px',
        left: bLeft + 'px',
        width: bWidth + 'px',
        height: bHeight + 'px',
      }"
      v-bind:class="{ active: bActive }"
      @mousedown="selectBox"
    ></div>
  </div>
</template>

<script>
export default {
  name: "BoxComp",
  props: [
    "b-top",
    "b-left",
    "b-width",
    "b-height",
    "on-select",
    "b-active",
    "b-index",
    "on-delete",
    "b-label",
    "disabled",
    "tracking",
  ],
  data() {
    return {
      boxLabel: "",
    };
  },
  methods: {
    selectBox() {
      if (!this.disabled) {
        this.onSelect(this.bIndex);
      }
    },
    removeMyself() {
      this.onDelete(this.bIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  position: absolute;
  border: 2px #8b78fa dashed;
  z-index: 99;
}

.box.enabled {
  &:hover,
  &.active {
    background-color: rgba($color: #8b78fa, $alpha: 0.5);
  }
}
.box-delete {
  position: absolute;
  z-index: 101;
  cursor: pointer;
}

input {
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 11pt;
  color: white;
  font-weight: 600;
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: white;
  }
}

.label {
  position: absolute;
  display: flex;
  align-items: center;
  border-radius: 6px 6px 0 0;
  height: 35px;
  font-size: 14pt;
  color: #fff;
  font-weight: bold;
  background-color: #8b78fa;
  opacity: 0.75;
  z-index: 100;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.active {
  border: 2px #8b78fa solid;
}
</style>
