import { db } from "@/firebase";
import store from "@/store";
import firebase from "firebase/";

const workspace = {
  state: {
    slides: [],
    workspace: {},
  },
  mutations: {
    setSlides(state, val) {
      state.slides = val;
    },
    setWorkspace(state, val) {
      state.workspace = val;
    },
  },
  actions: {
    fetchWorkspace({ commit, rootState }) {
      return new Promise((resolve) => {
        if (rootState.users.userProfile.workspace != "") {
          var docRef = db
            .collection("workspaces")
            .doc(rootState.users.userProfile.workspace);
          docRef
            .get()
            .then((doc) => {
              if (doc.exists) {
                commit("setWorkspace", doc.data());
                resolve("Workspace loaded");
              } else {
                resolve("No Workspace Found!");
              }
              store.dispatch("fetchSubscription");
            })
            .catch((error) => {
              console.log("Error getting workspace:", error);
              resolve("Workspace not found");
            });
        }
      });
    },
    fetchSlides({ commit }, uid) {
      return new Promise((resolve, reject) => {
        (async () => {
          var fetchSlides = firebase
            .app()
            .functions("europe-west1")
            .httpsCallable("fetchSlides");

          try {
            const res = await fetchSlides();
            console.log(res.data);
            commit("setSlides", res.data);
            resolve();
          } catch (error) {
            console.log(
              "You're probably in dev mode/localhost. If not, contact Max"
            );
            reject();
          }
        })();
      });
    },
  },
  getters: {},
};

export default workspace;
