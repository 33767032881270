import { db, auth } from "@/firebase";
import firebase from "firebase/";

const projects = {
  state: {
    projects: [],
    heatmaps: [],
    last: null,
    search: "",
  },
  mutations: {
    setProjects(state, val) {
      state.projects = val;
    },
    setProjectHeatmaps(state, val) {
      state.heatmaps = val;
    },
    setLastProject(state, val) {
      state.last = val;
    },
    setProjectSearch(state, val) {
      state.search = val;
    },
    addProjectHeatmap(state, val) {
      state.heatmaps = state.heatmaps.concat(val);
    },
    deleteProject(state, val) {
      let i = state.projects.findIndex((p) => p.uid === val);
      state.projects.splice(i, 1);
    },
    removeProjectHeatmap(state, val) {
      let index = state.heatmaps.findIndex((h) => h.uid === val);
      state.heatmaps.splice(index, 1);
    },
    updateProjectHeatmap(state, val) {
      let index = state.heatmaps.findIndex((h) => h.uid === val.uid);
      if (index < 0) {
        state.heatmaps.unshift(val);
        index = state.heatmaps.findIndex((h) => h.uid === val.uid);
      }
      if (val.template_scores) {
        state.heatmaps[index].template_scores.display =
          val.template_scores.display;
        state.heatmaps[index].template_scores.social =
          val.template_scores.social;
        state.heatmaps[index].template_scores.ooh = val.template_scores.ooh;
      }
      state.heatmaps[index].attention_scores = val.attention_scores;
      state.heatmaps[index].thumbnail = val.thumbnail;
      state.heatmaps[index].attention_scores = val.attention_scores;
      state.heatmaps[index].status = val.status;
      state.heatmaps[index].application = val.application;
      state.heatmaps[index].clarity_score_percentile =
        val.clarity_score_percentile;
      state.heatmaps[index].heatmap = val.heatmap;
      state.heatmaps[index].imageURL = val.imageURL;
      state.heatmaps[index].heatmapURL = val.heatmapURL;
      state.heatmaps[index].mask = val.mask;
      state.heatmaps[index].peekthrough = val.peekthrough;
      state.heatmaps[index].peekthroughURL = val.peekthroughURL;
      state.heatmaps[index].gazeplot = val.gazeplot;
      state.heatmaps[index].public = val.public;
      state.heatmaps[index].tags = val.tags;
      state.heatmaps[index].title = val.title;
      state.heatmaps[index].metrics = val.metrics;
      state.heatmaps[index].uid = val.uid;
      state.heatmaps[index].combined = val.combined;
      if (val.logo_frames) {
        state.heatmaps[index].logo_frames = val.logo_frames;
      }
      if (val.logo_tracking_status) {
        state.heatmaps[index].logo_tracking_status = val.logo_tracking_status;
      }
      if (val.logo_tracking_video) {
        state.heatmaps[index].logo_tracking_video = val.logo_tracking_video;
      }
    },
  },
  actions: {
    async fetchProjectHeatmaps({ commit }, val) {
      commit("setLoading", true);
      commit("setLastProject", val);
      var projectHeatmaps = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("projectHeatmaps");
      const res = await projectHeatmaps({ project: val });
      commit("setProjectHeatmaps", JSON.parse(res.data));
      commit("setLoading", false);
    },
    async deleteProject({ commit }, val) {
      await db.collection("projects").doc(val).delete();
      commit("deleteProject", val);
    },
    fetchProjects({ commit, rootState }) {
      return new Promise((resolve) => {
        let projects = [];
        let superadmin = false;
        let member = false;

        if (rootState.users.userProfile.superadmin) {
          superadmin = true;
        }
        if (
          rootState.users.userProfile.role === "member" ||
          rootState.users.userProfile.role === "admin"
        ) {
          member = true;
        }

        let query = db
          .collection("projects")
          .where("creator_uid", "==", auth.currentUser.uid)
          .where("workspace", "==", rootState.users.userProfile.workspace);
        if (superadmin) {
          query = db.collection("projects");
        } else if (member && rootState.users.userProfile.workspace != "") {
          query = db
            .collection("projects")
            .where("workspace", "==", rootState.users.userProfile.workspace);
        }

        query
          .get()
          .then((querySnapshot) => {
            querySnapshot.docs.map((doc) => {
              let p = doc.data();

              projects.push(p);
            });
          })
          .then(() => {
            projects.sort((a, b) =>
              a.title.toLowerCase() < b.title.toLowerCase()
                ? -1
                : b.title.toLowerCase() < a.title.toLowerCase()
                  ? 1
                  : 0
            );
            commit("setProjects", projects);
            resolve("Projects loaded");
          })
          .catch((error) => {
            resolve("Error getting projects: ", error);
          });
      });
    },
  },
  getters: {},
};

export default projects;
