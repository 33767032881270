<template>
  <div>
    <v-navigation-drawer class="bg-dark" permanent>
      <div
        class="d-flex justify-center align-center py-6"
        style="height: 100px"
      >
        <v-img
          v-if="!hover"
          @mouseenter="hover = true"
          contain
          max-width="30px"
          :src="require('@/assets/brand/logo_mark_white.svg')"
          style="cursor: pointer"
          @click="$router.push('/')"
        ></v-img>
        <v-btn
          density="compact"
          v-if="hover"
          @mouseleave="hover = false"
          variant="tonal"
          @click="$router.push('/')"
          icon="mdi-chevron-left"
        ></v-btn>
      </div>
      <v-list>
        <v-list-item
          nav
          style="font-size: 10pt"
          prepend-icon="mdi-view-dashboard"
          title="Dashboard"
          to="/superadmin/dashboard"
        ></v-list-item>
        <v-list-item
          nav
          style="font-size: 10pt"
          prepend-icon="mdi-account-multiple"
          title="Users"
          to="/superadmin/users"
        ></v-list-item>
        <v-list-item
          nav
          style="font-size: 10pt"
          prepend-icon="mdi-domain"
          title="Workspaces"
          to="/superadmin/workspaces"
        ></v-list-item>
        <v-list-item
          style="font-size: 10pt"
          nav
          prepend-icon="mdi-watermark"
          title="Models"
          to="/superadmin/models"
        ></v-list-item>
        <v-list-item
          style="font-size: 10pt"
          nav
          prepend-icon="mdi-chart-bell-curve"
          title="Benchmarks"
          to="/superadmin/benchmarks"
        ></v-list-item>
        <v-list-item
          nav
          style="font-size: 10pt"
          prepend-icon="mdi-api"
          title="API"
          to="/superadmin/api"
        ></v-list-item>
        <v-list-item
          style="font-size: 10pt"
          nav
          prepend-icon="mdi-presentation"
          title="Slides"
          to="/superadmin/slides"
        ></v-list-item>
        <v-list-item
          style="font-size: 10pt"
          nav
          prepend-icon="mdi-mail"
          title="Mail"
          to="/superadmin/mail"
        ></v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hover: false,
    };
  },
  watch: {
    $route: {
      handler: function (val) {
        if (val.path === "/superadmin/users") {
          this.tab = 0;
        } else if (val.path === "/superadmin/workspaces") {
          this.tab = 1;
        } else if (val.path === "/superadmin/models") {
          this.tab = 2;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.8, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateY(-50px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateY(50px);
  opacity: 0;
}
</style>
