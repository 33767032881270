<template>
  <v-dialog persistent :model-value="dialog" width="auto">
    <v-card width="400">
      <div class="pa-4 bg-primary d-flex justify-space-between align-center">
        <div>
          <p style="font-size: 14pt">Slide</p>
          <p style="font-size: 10pt">
            Workspace:
            <b>{{ slide && slide.workspace ? slide.workspace_name : "" }}</b>
          </p>
        </div>
        <v-btn
          icon="mdi-close"
          @click="$emit('close')"
          variant="flat"
          color="primary"
        ></v-btn>
      </div>
      <div class="pa-4">
        <v-text-field
          variant="solo"
          flat
          bg-color="smoke"
          hide-details
          class="mb-2"
          v-model="slide.title"
          label="title"
        ></v-text-field>
        <v-select
          variant="solo"
          flat
          hide-details
          label="Orientation"
          class="mb-2"
          bg-color="smoke"
          :items="['portrait', 'landscape']"
          v-model="slide.orientation"
        ></v-select>
        <v-select
          variant="solo"
          flat
          label="Application"
          hide-details
          class="mb-2"
          bg-color="smoke"
          :items="applications"
          multiple
          v-model="slide.application"
        ></v-select>
        <v-select
          v-model="slide.access"
          variant="solo"
          flat
          bg-color="smoke"
          label="Access"
          hide-details
          class="mb-2"
          :items="['admin', 'public', 'restricted']"
        ></v-select>
        <v-select
          v-if="slide.access === 'restricted'"
          variant="solo"
          flat
          label="Workspaces access"
          hide-details
          class="mb-2"
          bg-color="smoke"
          :items="workspaces"
          multiple
          item-title="name"
          item-value="uid"
          v-model="slide.access_restricted"
        ></v-select>
        <v-text-field
          variant="solo"
          flat
          bg-color="smoke"
          hide-details
          class="mb-2"
          v-model="slide.id"
          label="Presentation ID"
        ></v-text-field>
        <v-text-field
          variant="solo"
          flat
          hide-details
          class="mb-2"
          bg-color="smoke"
          v-model="slide.export_folder"
          label="Export folder ID"
        ></v-text-field>
      </div>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn @click="deleteItem"><v-icon>mdi-delete</v-icon></v-btn>
        <v-btn @click="save" color="primary">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { db } from "@/firebase";

export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      applications: (state) => state.defaults.defaults.heatmap.types,
      workspaces: (state) => state.admin.workspaces,
    }),
  },
  props: {
    dialog: {
      type: Boolean,
    },
    slide: {
      type: Object,
    },
  },
  data() {
    return {};
  },

  methods: {
    async deleteItem() {
      let r = confirm("Are you sure?");
      if (r) {
        this.$store.commit("setLoading", true);
        await db
          .collection("admin")
          .doc("slides")
          .collection("templates")
          .doc(this.slide.id)
          .delete();

        await this.$store.dispatch("fetchAdminSlides");
        this.$store.commit("setLoading", false);
        this.$emit("close");
      }
    },
    async save() {
      let r = this.slide.id;
      if (!r) {
        alert("ID needed to save slides");
        return;
      }
      await db
        .collection("admin")
        .doc("slides")
        .collection("templates")
        .doc(this.slide.id)
        .set(this.slide);

      this.$store.commit("setLoading", true);
      await this.$store.dispatch("fetchAdminSlides");
      this.$store.commit("setLoading", false);
      this.$emit("close");
    },
  },
};
</script>
