<template>
  <div id="useChart">
    <v-card flat class="pa-4" min-height="300" width="100%">
      <Bar
        id="my-chart-id"
        ref="chartcontainer"
        :options="chartOptions"
        :data="chartData"
      />
    </v-card>
  </div>
</template>

<script>
import { Line, Bar } from "vue-chartjs";
import annotationPlugin from "chartjs-plugin-annotation";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  BarElement,
  Filler,
} from "chart.js";

ChartJS.register(
  Title,
  Filler,
  Tooltip,
  BarElement,
  Legend,
  PointElement,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  annotationPlugin
);

export default {
  name: "LineChart",

  mounted() {
    this.chartWidth = this.$refs.chartcontainer.clientWidth;
    this.chartHeight = this.$refs.chartcontainer.clientHeight;

    var ro = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        this.chartWidth = cr.width;
        this.chartHeight = cr.height;
      }
    });

    // Observe one or multiple elements
    ro.observe(document.querySelector("#useChart"));
  },
  props: {
    stats: {
      default: null,
      type: Array,
    },
  },
  components: { Line, Bar },
  computed: {
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: true,
          mode: "index",
        },
        // plugins: {
        //   tooltip: {
        //     callbacks: {
        //       label: function (context) {
        //         return context.dataset.label + ": " + context.raw + "%";
        //       },
        //     },
        //     filter: function (tooltipItem) {
        //       return tooltipItem.dataset.percentiled;
        //     },
        //     enabled: true,
        //   },
        // },
        scales: {
          x: {
            grid: {
              display: false,
            },
            display: true,
          },
          y: {
            display: true,
            stacked: true,
            grid: {
              display: false,
            },
          },
        },
      };
    },
    chartData() {
      let cfg = {
        labels: [],
        datasets: [],
      };

      cfg.datasets.push({
        label: "Video minutes",
        backgroundColor: "#fbb81b",
        borderColor: "#fbb81b",
        data: this.stats.map((s) => Math.round(s.heatmaps.video_seconds / 60)),
        tension: 0.5,
        type: "bar",
        stack: 0,
        pointStyle: false,
      });

      cfg.datasets.push({
        label: "Images",
        backgroundColor: "#ff5555",
        borderColor: "#ff5555",
        data: this.stats.map((s) => s.heatmaps.image),
        tension: 0.5,
        type: "bar",
        stack: 0,
        pointStyle: false,
      });

      for (let step = 0; step <= this.stats.length - 1; step++) {
        cfg.labels.push(`${this.stats[step].year}-${this.stats[step].month}`);
      }

      return cfg;
    },
  },
  data() {
    return {
      chartWidth: null,
      chartHeight: null,
      left: null,
      brandColor: ["#FBB81B", "#00d38c", "#ff5555"],
    };
  },
};
</script>

<style lang="scss" scoped>
#useChart {
  position: relative;
}

.scrubber {
  width: 5px;
  background-color: #c4c4c4;
  opacity: 0.5;
  border-radius: 10px;
  position: absolute;
  z-index: 99;
}
</style>
