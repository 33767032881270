import { db, auth } from "@/firebase";
import firebase from "firebase/";
import store from "@/store";

const admin = {
  state: {
    api: [],
    benchmarks: [],
    loading: {
      benchmarks: false,
    },
    mail: [],
    models: [],
    workspaces: [],
    workspace: null,
    users: [],
    stats: null,
    stripe: null,
    slides: [],
  },
  mutations: {
    setAdminLoading(state, val) {
      state.loading[val.type] = val.value;
    },
    setModels(state, val) {
      state.models = val;
    },
    setAdminBenchmarks(state, val) {
      state.benchmarks = val;
      state.loading.benchmarks = false;
    },
    setAdminWorkspaces(state, val) {
      state.workspaces = val;
    },
    setAdminWorkspace(state, val) {
      state.workspace = val;
    },
    setAdminUsers(state, val) {
      state.users = val;
    },
    setAdminStats(state, val) {
      state.stats = val;
    },
    setAdminStripe(state, val) {
      state.stripe = val;
    },
    setAdminAPI(state, val) {
      state.api = val;
    },
    setAdminMail(state, val) {
      state.mail = val;
    },
    setAdminSlides(state, val) {
      state.slides = val;
    },
  },
  actions: {
    async fetchModels({ commit }) {
      return new Promise(async (resolve) => {
        let models = [];
        let r = await auth.currentUser.getIdTokenResult();

        if (r.claims.admin) {
          console.log("Fetching models");
          const querySnapshot = await db.collection("models").get();
          await Promise.all(
            querySnapshot.docs.map(async (m) => {
              let model = m.data();

              if (!model.last_update) {
                model.old_heatmaps = model.heatmaps;
              }
              model.heatmaps = [];

              let heatmapSnapshot = await db
                .collection("models")
                .doc(model.uid)
                .collection("labels")
                .get();

              heatmapSnapshot.docs.map(async (h) => {
                let hm = await db.collection("heatmaps").doc(h.id).get();
                let heatmap = hm.data();
                heatmap.labels = [];
                heatmap.labels = h.data().labels;
                model.heatmaps.push(heatmap);
              });

              const objectsSnapshot = await db
                .collection("models")
                .doc(model.uid)
                .collection("objects")
                .get();
              model.objects = [];
              objectsSnapshot.docs.map((o) => {
                model.objects.push(o.data());
              });
              models.push(model);
            })
          );
        }
        models.sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase()
            ? -1
            : b.name.toLowerCase() < a.name.toLowerCase()
              ? 1
              : 0
        );

        commit("setModels", models);
        resolve();
      });
    },
    async fetchAPI({ commit }) {
      let api = [];
      const workSnapshot = await db.collection("api").get();
      await Promise.all(
        workSnapshot.docs.map(async (ws) => {
          const w = await db.collection("workspaces").doc(ws.id).get();

          const requests = await db
            .collection("api")
            .doc(ws.id)
            .collection("requests")
            .get();

          await Promise.all(
            requests.docs.map(async (r) => {
              let res = r.data();

              if (res.webhook && res.webhook.combined_uid) {
                const wh = await db
                  .collection("webhooks")
                  .doc(res.webhook.combined_uid)
                  .get();
                res.webhook = wh.data();
              }

              api.push({
                ...res,
                workspace: {
                  name: w.data().name,
                  uid: ws.id,
                },
              });
            })
          );
        })
      );
      api.sort((a, b) =>
        a.timestamp < b.timestamp
          ? 1
          : b.timestamp < a.timestamp || !b.timestamp
            ? -1
            : 0
      );
      commit("setAdminAPI", api);
    },
    async fetchAdminMail({ commit }) {
      return new Promise(async (resolve, reject) => {
        (async () => {
          let mails = [];

          try {
            const querySnapshot = await db
              .collection("mail")
              .orderBy("delivery.startTime")
              .get();

            querySnapshot.docs.map((e) => mails.push(e.data()));

            commit("setAdminMail", mails);
            return resolve(mails);
          } catch (err) {
            return reject(err);
          }
        })();
      });
    },
    async fetchAdminSlides({ commit }) {
      return new Promise(async (resolve, reject) => {
        (async () => {
          let slides = [];

          try {
            const querySnapshot = await db
              .collection("admin")
              .doc("slides")
              .collection("templates")
              .get();

            querySnapshot.docs.map((e) => slides.push(e.data()));

            commit("setAdminSlides", slides);
            return resolve(slides);
          } catch (err) {
            return reject(err);
          }
        })();
      });
    },
    fetchAdminWorkspace({ commit }, val) {
      return new Promise((resolve, reject) => {
        (async () => {
          let ws;
          try {
            const doc = await db.collection("workspaces").doc(val).get();
            ws = doc.data();
            ws.members_data = [];
            ws.stats = [];
            await Promise.all(
              ws.members.map(async (mem) => {
                const memDoc = await db.collection("users").doc(mem).get();
                if (memDoc.exists) {
                  ws.members_data.push(memDoc.data());
                }
              })
            );

            const statsSnap = await db
              .collection("workspaces")
              .doc(ws.uid)
              .collection("stats")
              .get();

            await Promise.all(
              statsSnap.docs.map((s) => {
                ws.stats.push(s.data());
              })
            );

            ws.stats.sort((a, b) =>
              a.last_update < b.last_update
                ? -1
                : b.last_update < a.last_update
                  ? 1
                  : 0
            );
            console.log(ws);
            commit("setAdminWorkspace", ws);
            return resolve(ws);
          } catch (err) {
            return reject(err);
          }
        })();
      });
    },
    updateAdminWorkspace({ state, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        (async () => {
          commit("setLoading", true);

          let workspace = state.workspace;

          delete workspace.members_data;
          delete workspace.stats;

          // workspace.value = Number(workspace.value);

          try {
            await db
              .collection("workspaces")
              .doc(workspace.uid)
              .update(workspace);
            await dispatch("fetchAdminWorkspace", workspace.uid);
            commit("setLoading", false);
            commit("setSnackbar", {
              text: "Changes saved",
              timeout: 2000,
              show: true,
            });
            return resolve();
          } catch (err) {
            return reject(err);
          }
        })();
      });
    },
    async fetchAdminWorkspaces({ commit, rootState }) {
      return new Promise(async (resolve) => {
        let workspaces = [];
        console.log("fetching admin workspaces");

        if (rootState.users.userProfile.superadmin) {
          const querySnapshot = await db
            .collection("workspaces")
            .orderBy("name")
            .get();
          await Promise.all(
            querySnapshot.docs.map(async (m) => {
              let ws = m.data();
              const admn = await db.collection("users").doc(ws.admin).get();
              ws.members_data = [];

              await Promise.all(
                ws.members.map(async (mem) => {
                  const memDoc = await db.collection("users").doc(mem).get();
                  if (memDoc.exists) {
                    ws.members_data.push(memDoc.data());
                  }
                })
              );

              const querySnapshot = await db
                .collection("customers")
                .doc(ws.admin)
                .collection("subscriptions")
                .get();

              if (admn.exists) {
                ws.admin_data = admn.data();
              }
              querySnapshot.forEach((doc) => {
                let i = doc
                  .data()
                  .items.findIndex((p) => p && p.plan && p.plan.active);

                ws.subscription = {
                  active: i >= 0,
                  status: doc.data().status,
                  current_period_end: doc.data().current_period_end,
                };
              });

              workspaces.push(ws);
            })
          );
        }

        commit("setAdminWorkspaces", workspaces);
        resolve();
      });
    },
    async fetchAdminBenchmarks({ commit, rootState }) {
      return new Promise(async (resolve) => {
        commit("setAdminLoading", { type: "benchmarks", value: true });
        const querySnapshot = await db.collection("benchmarks").get();
        let benchmarks = [];

        await Promise.all(
          querySnapshot.docs.map(async (u) => {
            let bm = u.data();
            bm.subjects = [];
            bm.templates = [];

            const subSnap = await db
              .collection("benchmarks")
              .doc(u.id)
              .collection("subjects")
              .get();
            await Promise.all(
              subSnap.docs.map(async (sub) => {
                bm.subjects.push(sub.data());
              })
            );

            const tempSnap = await db
              .collection("benchmarks")
              .doc(u.id)
              .collection("templates")
              .get();
            await Promise.all(
              tempSnap.docs.map(async (sub) => {
                bm.templates.push(sub.data());
              })
            );

            benchmarks.push(bm);
          })
        );

        console.log(benchmarks);
        commit("setAdminBenchmarks", benchmarks);
        resolve();
      });
    },
    async fetchAdminUsers({ commit }) {
      return new Promise(async (resolve) => {
        const querySnapshot = await db
          .collection("users")
          .orderBy("name")
          .get();
        let users = [];
        await Promise.all(
          querySnapshot.docs.map(async (u) => {
            let user = u.data();
            if (user.workspace) {
              const memDoc = await db
                .collection("workspaces")
                .doc(user.workspace)
                .get();
              user.workspace_data = memDoc.data();
            }

            users.push(user);
          })
        );

        commit("setAdminUsers", users);
        resolve();
      });
    },
    async fetchAdminStats({ commit }) {
      const querySnapshot = await db.collection("stats").get();
      let stats = [];
      await Promise.all(
        querySnapshot.docs.map(async (u) => {
          stats.push(u.data());
        })
      );

      commit("setAdminStats", stats);
    },
    async fetchAdminStripe({ commit }) {
      var getStripe = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("stripeGetOptions");

      try {
        const res = await getStripe();
        commit("setAdminStripe", res.data);
      } catch (error) {
        console.log(
          "You're probably in dev mode/localhost. If not, contact Max"
        );
      }
    },
    async addSubscription({ commit }, val) {
      return new Promise(async (resolve) => {
        var addSub = firebase
          .app()
          .functions("europe-west1")
          .httpsCallable("stripeAddSubscription");

        const res = await addSub(val);
        console.log(res);
        commit("setSnackbar", {
          text:
            res.data === 200
              ? "Subscription added!"
              : "Something went wrong.. Check Stripe manually.",
          timeout: 3000,
          show: true,
        });
        resolve();
      });
    },
    async predict({ commit }, val) {
      commit("setLoading", true);
      console.log(val);
      const m = await db.collection("models").doc(val.model.uid).get();
      let model = m.data();
      console.log(model);
      if (!model.hasOwnProperty("last_update")) {
        model.old_heatmaps = model.heatmaps;
      }
      model.heatmaps = [];

      let heatmapSnapshot = await db
        .collection("models")
        .doc(model.uid)
        .collection("labels")
        .get();

      await Promise.all(
        heatmapSnapshot.docs.map(async (h) => {
          let hm = await db.collection("heatmaps").doc(h.id).get();
          let heatmap = hm.data();
          heatmap.labels = [];
          heatmap.labels = h.data().labels;
          model.heatmaps.push(heatmap);
        })
      );

      const objectsSnapshot = await db
        .collection("models")
        .doc(model.uid)
        .collection("objects")
        .get();
      model.objects = [];
      await Promise.all(
        objectsSnapshot.docs.map((o) => {
          model.objects.push(o.data());
        })
      );

      if (val.heatmap.logo_tracking_status) {
        await db.collection("heatmaps").doc(val.heatmap.uid).update({
          logo_tracking_status: "processing",
          logo_tracking_video: null,
        });
      }

      let texts = [];
      if (model.objects.filter((o) => o.type === "text").length > 0) {
        model.objects.map((o) => {
          if (o.type === "text") {
            texts.push(o.name);
          }
        });
      }
      if (val.text) {
        let txt = val.text.split(" ");
        for (let x in txt) {
          texts.push(txt[x]);
        }
      }
      let onlyText =
        model.objects.filter((o) => o.type === "image").length === 0;

      let reqData = {
        remote_model_path: onlyText
          ? ""
          : `models/${model.uid}-v${model.version}.pt`,
        remote_path: `${val.heatmap.requested_by}/${val.heatmap.uid}`,
        filepath: `${val.heatmap.uid}.${val.heatmap.extension}`,
        worker: "predict",
        text: texts,
      };
      console.log(reqData);

      var requestPrediction = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("requestPrediction");

      const res = await requestPrediction(reqData);
      console.log(res.data);

      commit("setLoading", false);
      store.commit("setSnackbar", {
        text: "Brand Recognition Requested",
        timeout: 2000,
        show: true,
      });
    },
  },
};

export default admin;
