<template>
  <div v-if="heatmap && !isLoading">
    <div
      class="heatmap-wrapper"
      :style="{
        'grid-template-columns': chat_show ? '290px auto 400px' : '290px auto',
      }"
    >
      <div class="left px-6 pt-6" style="height: 100%">
        <Import
          :dialog="importAOI"
          @dialog="setDialogImport"
          @importAOI="importAOIfromHeatmap"
          :selectedProp="importAOIs"
        />
        <BrandDialog
          :heatmap="heatmap"
          @requested="heatmap.logo_tracking_status = 'requested'"
          v-if="
            (!heatmap.logo_tracking_status && heatmap.type === 'video') ||
            (heatmap.type === 'video' && userProfile.superadmin)
          "
        />

        <div
          v-if="
            workspace.access && workspace.access.includes('object_recognition')
          "
        >
          <v-alert
            v-if="
              heatmap.logo_tracking_status === 'processing' ||
              heatmap.logo_tracking_status === 'requested'
            "
            :key="heatmap.logo_tracking_status"
            class="mt-2"
            type="info"
            color="smoke"
            style="font-size: 10pt"
            >The brand analytics on this video is being processed and might take
            up to a few days.
          </v-alert>
        </div>

        <OptionsButtons
          :heatmap="heatmap"
          :attention_score="attention_score"
          :models="models"
          :userProfile="userProfile"
          @download="downloadHeatmap"
          @downloadAll="downloadAll"
          @share="share"
          @copy="copy"
          @ready="fetchData"
          class="mb-2"
        />
        <div key="Details">
          <div>
            <v-text-field
              label="Title"
              placeholder="Enter a title for this heatmap"
              color="white"
              variant="solo"
              flat=""
              class="mb-2"
              hide-details=""
              v-model="heatmap.title"
              @change="save"
              bg-color="white"
            >
            </v-text-field>
            <div class="my-2 d-flex align-center">
              <v-divider></v-divider>
              <v-btn
                class="mx-4"
                :icon="showDetails ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                size="small"
                density="compact"
                variant="tonal"
                flat
                @click="showDetails = !showDetails"
              ></v-btn>
              <v-divider></v-divider>
            </div>
            <v-expand-transition>
              <div v-show="showDetails">
                <v-text-field
                  label="Type"
                  readonly
                  variant="solo"
                  bg-color="white"
                  flat=""
                  class="mb-2"
                  hide-details="true"
                  v-model="heatmap.application"
                  @change="save"
                ></v-text-field>
                <v-select
                  v-if="heatmap.application === 'UX Design'"
                  :items="device"
                  label="Device (readonly)"
                  class="my-2"
                  v-model="heatmap.device"
                  readonly
                  hide-details=""
                  @change="save"
                  variant="solo"
                  bg-color="white"
                  flat=""
                  :menu-props="{ rounded: true, offsetY: true }"
                ></v-select>
                <v-autocomplete
                  :items="projects"
                  @change="save"
                  v-model="projectIds"
                  multiple
                  item-value="uid"
                  item-text="title"
                  variant="solo"
                  bg-color="white"
                  flat=""
                  label="Project"
                  hide-details
                  clearable
                  :menu-props="{ rounded: true, offsetY: true }"
                ></v-autocomplete>
                <v-combobox
                  label="Tags"
                  :items="tags"
                  chips
                  multiple
                  variant="solo"
                  bg-color="white"
                  flat=""
                  clearable
                  hint="Add new tags by typing and press enter"
                  persistent-hint
                  class="my-2"
                  v-model="heatmap.tags"
                  @change="save"
                  :menu-props="{ rounded: true, offsetY: true }"
                ></v-combobox>
              </div>
            </v-expand-transition>
          </div>
        </div>

        <div key="Attention">
          <AttentionCard
            :attention_score="attention_score"
            :type="heatmap.type"
            :application="heatmap.application"
            class="mb-2"
            v-if="attention_score"
          />

          <CreativeBreakthrough
            :heatmap="heatmap"
            :oohScore="oohScore"
            :displayScore="displayScore"
            :socialScore="socialScore"
            v-if="analytics === 'Attention'"
          />
        </div>

        <div key="Clarity">
          <ClarityCard class="mb-2" :heatmap="heatmap" />
        </div>

        <BrandAttention
          :metrics="heatmap.brand"
          :duration="heatmap.duration"
          v-if="heatmap.logo_tracking_status === 'done'"
        />

        <div key="Analytics">
          <v-select
            v-if="analytics === 'Overlays'"
            solo
            flat
            :items="[
              {
                text: 'Instagram Story',
                value: 'instagram_story',
              },
            ]"
            hide-details=""
            v-model="application_type"
            class="mb-2"
            return-object
          ></v-select>
        </div>

        <v-card
          v-if="heatmap.type === 'image'"
          class="pa-6"
          elevation="0"
          min-width="150"
          style="z-index: 10"
        >
          <div>
            <div class="d-flex pa-0">
              <h4 class="white--text font-weight-medium">
                Area's of Interest:
              </h4>
              <v-spacer></v-spacer>
              <!-- <v-btn text @click="importAOI = true">Import</v-btn> -->
              <v-btn
                v-if="boxes.length > 0"
                variant="text"
                @click="showBoxes = !showBoxes"
              >
                <span v-if="showBoxes">Hide</span><span v-else>Show</span>
              </v-btn>
            </div>

            <div
              class="aoi-title mt-6"
              v-if="boxes.length === 0 && variant != 'Peekthrough'"
            >
              <p style="margin-bottom: 10px; font-size: 10pt">
                Start drawing boxes on your heatmap to calculate the percentage
                of attention.
              </p>
              <img src="./aoi.svg" alt="" v-if="boxes.length === 0" />
            </div>
            <div v-else-if="variant === 'Peekthrough'" class="mt-6">
              <u style="cursor: pointer" @click="variant = 'Heatmap'"
                >Set to heatmap</u
              >
              before creating areas of interest.
            </div>
            <div class="box-wrapper">
              <div
                v-for="(box, index) in boxes"
                :key="index"
                class="box"
                :style="{ width: '100%', 'z-index': 11 }"
                @mouseover="boxHover = index"
                @mouseleave="boxHover = null"
                @click="selectAOI(index)"
                v-bind:class="{ active: activeBoxIndex === index }"
              >
                <p>
                  {{ box.name }}
                </p>
                <div>
                  <p v-if="boxHover != index" style="color: #6515dd">
                    <b>{{ Math.round(box.value * 100) }}%</b>
                  </p>
                  <div v-else>
                    <v-btn
                      variant="tonal"
                      density="comfortable"
                      size="small"
                      @click="deleteAOI(index)"
                      icon="mdi-delete"
                    ></v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>

        <!-- <Guides :heatmap="heatmap" v-else-if="navigation === 'Guides'" /> -->
      </div>

      <div class="middle mr-10 pt-6" id="topcard" ref="topcard">
        <TextInAd
          :image="heatmap.imageURL"
          :uid="heatmap.uid"
          :selected="heatmap.text_in_ad ? heatmap.text_in_ad : []"
          v-if="navigation === 'Analytics' && analytics === 'Text in Ad'"
        />

        <Overlays
          :heatmap="heatmap"
          :application_type="application_type"
          v-else-if="navigation === 'Analytics' && analytics === 'Overlays'"
        />

        <div
          class="image-heatmap"
          :style="{ display: ratio > 1 ? 'block' : 'inline-block' }"
          v-else-if="!loading"
        >
          <div v-if="isLoading" class="loader">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>

          <v-card
            max-width="1000px"
            flat
            v-if="
              !isLoading && heatmapData.length > 0 && heatmap.type === 'image'
            "
            class="pa-4"
          >
            <Heatmap
              :variant="variant"
              :showBoxes="showBoxes"
              :data="heatmapData"
              :image="heatmap.imageURL"
              :image_result="heatmap.combined"
              :peekthrough="heatmap.peekthrough"
              :heatmap="heatmap.heatmap"
              :gazeplot="heatmap.gazeplot ? heatmap.gazeplot : null"
              :loadBoxes="heatmap.areas_of_interest"
              :originalWidth="originalWidth"
              :opacity="slider"
              :uid="heatmap.uid"
              :compare="false"
              :type="heatmap.type"
              :slider="slider"
              :combined="heatmap.combined"
              ref="heatmap"
              @onSelectBox="selectBox"
              @boxUpdate="updateBoxes"
              @update="refreshCompare"
              @newBox="addAOI"
              @updateName="updateName"
              @initialized="initialized"
              :key="reKey"
              style="position: relative"
            />
          </v-card>

          <v-card
            class="bg-primary"
            dark
            v-if="heatmap.type === 'image' && !isLoading"
            style="
              border-radius: 0px 10px 10px 0;
              position: absolute;
              right: -44px;
              top: 50px;
              z-index: 98;
              display: flex;
              flex-direction: column;
              gap: 10px;
              padding: 10px;
            "
          >
            <v-btn
              size="small"
              variant="text"
              icon="mdi-eye"
              @click="variant = 'Heatmap'"
              :color="variant === 'Heatmap' ? 'success' : 'white'"
            >
            </v-btn>
            <v-btn
              @click="variant = 'Peekthrough'"
              size="small"
              variant="text"
              icon="mdi-domino-mask"
              :color="variant === 'Peekthrough' ? 'success' : 'white'"
            >
            </v-btn>
            <v-btn
              @click="variant = 'Gazeplot'"
              size="small"
              variant="text"
              icon="mdi-scatter-plot"
              :color="variant === 'Gazeplot' ? 'success' : 'white'"
            >
            </v-btn>
            <v-btn
              v-if="heatmap.logo_tracking_video"
              @click="variant = 'Brand detection'"
              size="small"
              variant="text"
              icon="mdi-watermark"
              :color="variant === 'Brand detection' ? 'success' : 'white'"
            >
            </v-btn>
            <v-btn
              @click="variant = 'Original'"
              size="small"
              variant="text"
              icon="mdi-image"
              :color="variant === 'Original' ? 'success' : 'white'"
            >
            </v-btn>
          </v-card>
          <v-card
            class="bg-primary slider"
            dark
            v-if="
              this.variant === 'Heatmap' &&
              heatmap.type === 'image' &&
              !isLoading
            "
            :style="{
              maxWidth:
                this.height > this.width ? `${this.width - 5}px` : '400px',
              left: this.height > this.width ? '5px' : '10%',
            }"
            style="
              position: absolute;
              left: 10%;
              z-index: 98;
              bottom: -29px;
              display: flex;
              border-radius: 0 0 10px 10px;
              padding: 10px 20px;
              width: 81%;
            "
          >
            <v-slider
              v-model="slider"
              label="Background"
              color="smoke"
              step="10"
              hide-details=""
            ></v-slider>
          </v-card>

          <div
            v-else-if="!isLoading && heatmap.type === 'video'"
            class="video-container"
          >
            <div style="position: relative; display: inline-block">
              <v-card class="pa-4" flat max-width="1000">
                <Transition mode="out-in" name="slide-up">
                  <video
                    v-if="variant === 'Heatmap'"
                    :src="heatmap.heatmapURL"
                    controls
                    width="60%"
                    key="heatmap"
                    @canplay="getRatio"
                    @timeupdate="timeUpdate"
                    ref="vid"
                    style="border: 2px solid #6515dd; border-radius: 6px"
                    :style="{
                      maxHeight:
                        navigation === 'Analytics' && metric ? '55vh' : '70vh',
                    }"
                  ></video>
                  <video
                    @canplay="getRatio"
                    key="peekthrough"
                    v-else-if="variant === 'Peekthrough'"
                    :src="heatmap.peekthroughURL"
                    @timeupdate="timeUpdate"
                    controls
                    width="60%"
                    style="border: 2px solid #6515dd; border-radius: 6px"
                    ref="vid"
                    :style="{
                      maxHeight:
                        navigation === 'Analytics' && metric ? '55vh' : '70vh',
                    }"
                  ></video>
                  <video
                    @canplay="getRatio"
                    key="brand"
                    v-else-if="variant === 'Brand detection'"
                    :src="heatmap.logo_tracking_video"
                    @timeupdate="timeUpdate"
                    controls
                    width="60%"
                    ref="vid"
                    style="border: 2px solid #6515dd; border-radius: 6px"
                    :style="{
                      maxHeight:
                        navigation === 'Analytics' && metric ? '55vh' : '70vh',
                    }"
                  ></video>
                  <video
                    @canplay="getRatio"
                    key="original"
                    v-else-if="variant === 'Original'"
                    :src="heatmap.imageURL"
                    @timeupdate="timeUpdate"
                    controls
                    ref="vid"
                    style="border: 2px solid #6515dd; border-radius: 6px"
                    width="60%"
                    :style="{
                      maxHeight:
                        navigation === 'Analytics' && metric ? '55vh' : '70vh',
                    }"
                  ></video>
                </Transition>

                <v-expand-transition>
                  <div
                    class="mt-2 px-1"
                    style="width: 100%"
                    v-if="
                      videoLoaded && metricsLoaded
                      // &&(heatmap.type === 'video' ||
                      //   (navigation === 'Attention' &&
                      //     heatmap.logo_tracking_status === 'done'))
                    "
                  >
                    <ChartJS
                      :currentTime="currentTime"
                      :duration="videoDuration"
                      :clarity="heatmap.clarity"
                      :attention="heatmap.attention"
                      :brand="heatmap.brand"
                      :type="metric"
                      :metric_types="metric_types"
                    />
                  </div>
                </v-expand-transition>
              </v-card>
              <v-card
                class="bg-primary"
                dark
                style="
                  border-radius: 0px 10px 10px 0;
                  position: absolute;
                  right: -88px;
                  top: 50px;
                  z-index: 98;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                "
              >
                <v-btn
                  @click="variant = 'Heatmap'"
                  prepend-icon="mdi-eye"
                  variant="text"
                  size="small"
                  style="font-size: 8pt"
                  stacked
                  :color="variant === 'Heatmap' ? 'success' : 'white'"
                  >Heatmap
                </v-btn>
                <v-btn
                  @click="variant = 'Peekthrough'"
                  prepend-icon="mdi-domino-mask"
                  variant="text"
                  size="small"
                  stacked
                  style="font-size: 8pt"
                  :color="variant === 'Peekthrough' ? 'success' : 'white'"
                  >Peekthrough
                </v-btn>

                <v-btn
                  v-if="heatmap.logo_tracking_video"
                  @click="variant = 'Brand detection'"
                  prepend-icon="mdi-watermark"
                  variant="text"
                  size="small"
                  stacked
                  style="font-size: 8pt"
                  :color="variant === 'Brand detection' ? 'success' : 'white'"
                  >Brand
                </v-btn>
                <v-btn
                  stacked
                  @click="variant = 'Original'"
                  prepend-icon="mdi-video"
                  variant="text"
                  size="small"
                  style="font-size: 8pt"
                  :color="variant === 'Original' ? 'success' : 'white'"
                  >Original
                </v-btn>
              </v-card>
            </div>
          </div>
        </div>
      </div>
      <!-- <Chat /> -->
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase";
import moment from "moment";
import { mapState } from "vuex";
import firebase from "firebase/";
import Heatmap from "./heat/HeatBox.vue";
import store from "@/store";
// import Guides from "@/components/guides/GuidesCard.vue";
import Import from "@/components/importAOI/ImportAoi.vue";
import Chart from "./chart/ChartCard.vue";
const axios = require("axios");
import mergeImages from "merge-images";
import ClarityCard from "./clarityCard/ClarityCard.vue";
import AttentionCard from "./attentionCard/AttentionCard.vue";
import TextInAd from "./Analytics/TextInAd.vue";
import Overlays from "./Analytics/Overlays/AnalyticsOverlay.vue";
import BrandAttention from "./brandAttention/BrandAttention.vue";
import CreativeBreakthrough from "./creativeBreakthrough/CreativeBreakthrough.vue";
import ChartJS from "./ChartJS/chartJs.vue";
import OptionsButtons from "./OptionsButtons/OptionsButtons.vue";
import Chat from "@/components/chatComp/chatComp.vue";
import BrandDialog from "./brandAnalytics/BrandDialog.vue";

export default {
  name: "HeatmapView",
  components: {
    Chat,
    OptionsButtons,
    CreativeBreakthrough,
    BrandAttention,
    Heatmap,
    // Guides,
    BrandDialog,
    ClarityCard,
    Import,
    Chart,
    AttentionCard,
    TextInAd,
    Overlays,
    ChartJS,
  },
  data() {
    return {
      activeBoxIndex: null,
      analytics: "Clarity",
      application_type: {
        text: "Instagram Story",
        value: "instagram_story",
      },
      baseblue: "",
      baseimg: "",
      baseheat: "",
      boxes: [],
      brandDialog: false,
      boxHover: null,
      chat_show: false,
      comparison: [],
      comparing: false,
      comment: "",
      copied: false,
      currentTime: null,
      deleteId: null,
      dialogDelete: false,
      device: ["desktop", "mobile"],
      emails: [{ email: "" }],
      endTime: null,
      startTime: null,
      heatmap: null,
      heatmapData: [],
      heatmap_opacity: "",
      height: null,
      importAOI: false,
      importAOIs: [],
      isBoxesUpdating: false,
      isLoading: true,
      loadedBoxes: false,
      metric: [],
      metric_types: ["clarity", "attention", "brand"],
      metricsLoaded: false,
      navigation: "Details",
      originalWidth: null,
      originalHeight: null,
      projectIds: [],
      reKey: 0,
      ratio: null,
      shareMenu: false,
      slider: 0,
      slideDirection: "right",
      show: true,
      showDetails: false,
      showBoxes: true,
      show_details: false,
      switch1: false,
      switch2: false,
      timeline: [],
      variant: "Heatmap",
      values: [],
      videoDuration: null,
      videoLoaded: false,
      width: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      users: (state) => state.users.users,
      workspace: (state) => state.workspace.workspace,
      heatmaps: (state) => state.heatmaps.heatmaps,
      projects: (state) => state.projects.projects,
      tags: (state) => state.heatmaps.tags,
      defaults: (state) => state.defaults.defaults,
      subscription: (state) => state.subscription.subscription,
      reports: (state) => state.reports.reports,
      models: (state) => state.admin.models,
    }),
    filteredHeatmaps() {
      const heatmaps = this.heatmaps.filter(
        (h) => h.type === this.heatmap.type
      );

      return heatmaps;
    },
    compareTab() {
      return false;
    },
    attention_score() {
      if (!this.heatmap) {
        return null;
      } else if (this.heatmap.attention_score) {
        return this.heatmap.attention_score;
      } else if (this.heatmap.metrics.attention_score) {
        return this.heatmap.metrics.attention_score;
      } else {
        let score = null;
        let templates =
          this.heatmap.type === "image" &&
          this.heatmap.application != "UX Design" &&
          this.heatmap.application != "Other" &&
          this.heatmap.application != "Ad / commercial (video)";
        if (this.heatmap.template_scores && templates) {
          let type = null;
          switch (this.heatmap.application) {
            case "Display Ads":
              type = "display";
              break;
            case "(Digital) Out Of Home":
              type = "ooh";
              break;
            case "Social media":
              type = "social";
              break;
          }
          const display = this.heatmap.template_scores[type];
          let sum = 0;
          let count = 0;
          Object.keys(display).forEach(function (key) {
            count++;
            sum = sum + display[key];
          });
          score = sum / count;
        }

        if (
          this.heatmap.application === "Display Ads" &&
          this.heatmap.type === "video" &&
          this.heatmap.attention_scores &&
          this.heatmap.attention_scores.length > 0
        ) {
          const att = this.heatmap.attention_scores;
          let sum = 0;
          let count = 0;

          att.forEach((a) => {
            sum = sum + a.score;
            count = count + 1;
          });
          score = sum / count;
        } else if (
          this.heatmap.application === "(Digital) Out Of Home" &&
          this.heatmap.type === "video" &&
          this.heatmap.attention_scores &&
          this.heatmap.attention_scores.length > 0
        ) {
          const att = this.heatmap.attention_scores;
          let sum = 0;
          let count = 0;

          att.forEach((a) => {
            sum = sum + a.score;
            count = count + 1;
          });
          score = sum / count;
        } else if (
          this.heatmap.application === "Social media" &&
          this.heatmap.type === "video" &&
          this.heatmap.attention_scores &&
          this.heatmap.attention_scores.length > 0
        ) {
          const att = this.heatmap.attention_scores;
          let sum = 0;
          let count = 0;

          att.forEach((a) => {
            sum = sum + a.score;
            count = count + 1;
          });
          score = sum / count;
        }
        return score;
      }
    },

    heatmapItems() {
      let list = [
        "Heatmap",
        "Brand detection",
        "Peekthrough",
        "Gazeplot",
        "Original",
      ];

      if (this.heatmap.type === "video") {
        list.splice(3, 1);
      }
      if (this.heatmap.logo_tracking_status != "done") {
        list.splice(1, 1);
      }
      return list;
    },
    analyticsOptions() {
      if (this.heatmap.type === "video") {
        let list = ["Clarity"];
        if (
          (this.heatmap.attention_scores &&
            this.heatmap.attention_scores.length > 0) ||
          this.heatmap.logo_tracking_status === "done"
        ) {
          list.push("Attention");
        }
        return list;
      } else {
        return ["Attention", "Clarity", "Overlays", "Text In Ad"];
      }
    },
  },
  beforeDestroy() {
    this.loadedBoxes = false;
    this.copied = false;
    this.$store.commit("setLoading", false);
  },
  methods: {
    async clear() {
      let new_tracking_results = this.heatmap.tracking_results;

      console.log("start time: " + this.startTime);
      console.log("end time: " + this.endTime);
      console.log(this.endTime / this.heatmap.duration);
      let start_frame = Math.floor(
        (this.startTime / this.heatmap.duration) *
          this.heatmap.tracking_results.length
      );
      let end_frame = Math.ceil(
        (this.endTime / this.heatmap.duration) *
          this.heatmap.tracking_results.length
      );
      console.log(start_frame);
      console.log(end_frame);

      new_tracking_results = new_tracking_results.map((f) => {
        if (f.frame_number >= start_frame && f.frame_number <= end_frame) {
          f.objects = [];
          f.textx = [];
        }
        return f;
      });

      console.log(new_tracking_results);
      await db.collection("heatmaps").doc(this.heatmap.uid).update({
        tracking_results: new_tracking_results,
      });

      this.fetchData();
    },
    async addAOI(val) {
      console.log(val);
      // const res = await API.test.createAOI(this.$route.params.heatmapId, val);
    },
    addComment() {
      const vm = this;
      db.collection("heatmaps")
        .doc(vm.heatmap.uid)
        .collection("timeline")
        .add({
          comment: vm.comment,
          added_by: vm.userProfile.uid,
          added_by_name: vm.userProfile.name,
          timestamp: new Date(),
          avatar: vm.userProfile.avatar,
        })
        .then((docRef) => {
          var commentRef = db
            .collection("heatmaps")
            .doc(vm.heatmap.uid)
            .collection("timeline")
            .doc(docRef.id);

          commentRef
            .update({
              uid: docRef.id,
            })
            .then(() => {
              console.log("Document successfully updated!");
            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
          vm.comment = "";
          vm.fetchComments();
        })
        .then(() => {
          const vm = this;
          var ref = db.collection("heatmaps").doc(vm.$route.params.id);

          ref.update({
            timeline: firebase.firestore.FieldValue.increment(1),
          });
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    changeSwitch() {
      if (this.switch2) {
        this.switch1 = true;
      }
    },
    changeHeatmap(val) {
      this.$router.push(`/heatmap/${val.uid}`);
      this.fetchData();
    },
    closeCard(i) {
      const index = this.comparison.findIndex((h) => h.uid === i);
      this.comparison.splice(index, 1);
    },
    copy() {
      navigator.clipboard
        .writeText(`https://ai.brainsight.app/viewonly/${this.heatmap.uid}`)
        .then(
          function () {
            console.log("copied");
          },
          function () {
            console.log("copy failed");
          }
        );
    },
    async createTestHeatmap() {
      this.heatmapData = [];
      const imageOriginal = await fetch(this.heatmap.imageURL);
      const imgblobOriginal = await imageOriginal.blob();
      const imgOriginal = await createImageBitmap(imgblobOriginal);
      this.originalWidth = imgOriginal.width;
      this.originalHeight = imgOriginal.height;

      var blue = document.createElement("canvas");
      blue.width = imgOriginal.width;
      blue.height = imgOriginal.height;
      var ctx = blue.getContext("2d");
      ctx.fillStyle = "#1821F0";
      ctx.fillRect(0, 0, blue.width, blue.height);
      this.baseblue = blue.toDataURL();

      const image = await fetch(this.heatmap.mask);
      const imgblob = await image.blob();
      const img = await createImageBitmap(imgblob);
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ratio = this.originalWidth / img.width;
      console.log("ratio: " + ratio);

      canvas.getContext("2d").drawImage(img, 0, 0, img.width, img.height);
      console.log(img.width);
      console.log(img.height);
      for (let x = 0; x < img.width; x++) {
        for (let y = 0; y < img.height; y++) {
          var pixelData = canvas.getContext("2d").getImageData(x, y, 1, 1).data;

          const Y =
            0.2126 * pixelData[0] +
            0.7152 * pixelData[1] +
            0.0722 * pixelData[2];

          this.heatmapData.push({
            x: x * ratio,
            y: y * ratio,
            value: Y * (ratio / 100),
          });
        }
      }
      this.isLoading = false;
      store.commit("setLoading", false);
    },
    async deleteAOI(i) {
      const ref = this.$refs.heatmap;
      ref.removeBox(i);
    },
    downloadAll() {
      this.downloadHeatmap(1);
      this.downloadHeatmap(2);
      this.downloadHeatmap(3);
      if (this.heatmap.gazeplot) {
        this.downloadHeatmap(4);
      }
      if (this.heatmap.logo_tracking_video) {
        this.downloadHeatmap(5);
      }
    },
    async downloadHeatmap(v) {
      console.log(v);
      const h = this.heatmap;
      var storage = firebase.storage();
      var storageRef = firebase.storage().ref();
      const storagePath = storageRef.child(`${h.requested_by}/${h.uid}`);
      var imageRef = storage.refFromURL(`${storagePath}`);

      if (h.type === "image") {
        const b64 = await mergeImages([
          { src: this.baseimg },
          {
            src: this.baseblue,
            opacity: this.slider > 0 ? this.slider / 100 : 0.01,
          },
          { src: this.baseheat },
        ]);
        this.heatmap_opacity = b64;
      }

      if (h.type === "image") {
        let path = `${h.uid}.${h.extension}`;
        let label = "";
        if (this.variant === "Heatmap") {
          label = `${h.title} - Heatmap`;
          path = `${h.uid}-combined.png`;
        } else if (this.variant === "Peekthrough") {
          label = `${h.title} - Peekthrough`;
          path = `${h.uid}-peekthrough.png`;
        } else if (this.variant === "Gazeplot") {
          label = `${h.title} - Gazeplot`;
          path = `${h.uid}-gazeplot.png`;
        } else {
          label = `${h.title}`;
        }
        if (v === 1) {
          label = `${h.title} - Heatmap`;
          path = `${h.uid}-combined.png`;
        } else if (v === 2) {
          label = `${h.title} - Peekthrough`;
          path = `${h.uid}-peekthrough.png`;
        } else if (v === 3) {
          label = `${h.title}`;
          path = `${h.uid}.${h.extension}`;
        } else if (v === 4) {
          label = `${h.title} - Gazeplot`;
          path = `${h.uid}-gazeplot.png`;
        }

        if (v === 1) {
          const link = document.createElement("a");
          link.href = this.heatmap_opacity;
          link.download = label + ".png";
          link.click();
          URL.revokeObjectURL(link.href);
        } else {
          const url = await imageRef.child(path).getDownloadURL();
          axios
            .get(url, { responseType: "blob" })
            .then((response) => {
              const blob = new Blob([response.data], { type: "image/png" });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = label + ".png";
              link.click();
              URL.revokeObjectURL(link.href);
            })
            .catch(console.error);
        }
      } else {
        let path = `${h.uid}.mp4`;
        let label = "";

        if (v === 1) {
          label = `${h.title} - Heatmap`;
          path = `${h.uid}-combined.mp4`;
        } else if (v === 2) {
          label = `${h.title} - Peekthrough`;
          path = `${h.uid}-peekthrough.mp4`;
        } else if (v === 3) {
          label = `${h.title}`;
          path = `${h.uid}.mp4`;
        } else if (v === 5) {
          label = `${h.title} - Brand Recognition`;
          path = `${h.uid}-output.mp4`;
        }

        const url = await imageRef.child(path).getDownloadURL();
        axios
          .get(url, { responseType: "blob" })
          .then((response) => {
            const blob = new Blob([response.data], { type: "video/mp4" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = label + ".mp4";
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch(console.error);
      }
    },
    async fetchData() {
      this.$store.commit("setLoading", true);
      const vm = this;
      const doc = await db
        .collection("heatmaps")
        .doc(this.$route.params.id)
        .get();
      this.heatmap = doc.data();

      if (this.heatmap.type === "video") {
        // Metrics: Video Clarity Graph
        if (
          (this.heatmap.application != "Other" && this.heatmap.metrics) ||
          this.heatmap.hasOwnProperty("clarity_scores_frames")
        ) {
          var videoClarity = firebase
            .app()
            .functions("europe-west1")
            .httpsCallable("videoClarity");
          const videoClarityRes = await videoClarity(
            JSON.stringify(this.heatmap)
          );

          this.heatmap.clarity = videoClarityRes.data;
        } else {
          this.heatmap.clarity = null;
        }

        // Metrics: Brand Attention
        if (
          this.heatmap.logo_tracking_status &&
          this.heatmap.logo_tracking_status === "done"
        ) {
          var brandAttention = firebase
            .app()
            .functions("europe-west1")
            .httpsCallable("brandAttention");
          const brandAttentionRes = await brandAttention(
            JSON.stringify(this.heatmap)
          );
          this.heatmap.brand = brandAttentionRes.data;
        } else {
          this.heatmap.brand = null;
        }

        // Metrics: Attention
        if (
          this.heatmap.attention_scores &&
          this.heatmap.attention_scores.length > 0
        ) {
          var videoAttention = firebase
            .app()
            .functions("europe-west1")
            .httpsCallable("videoAttention");
          const att = await videoAttention(JSON.stringify(this.heatmap));
          this.heatmap.attention = att.data;
        } else {
          this.attention = null;
        }

        this.metricsLoaded = true;
      } else if (vm.heatmap.type === "image") {
        vm.createTestHeatmap();

        axios
          .get(this.heatmap.imageURL, { responseType: "blob" })
          .then(async (response) => {
            const blob = new Blob([response.data], { type: "image/png" });
            this.baseimg = await this.toBase64(blob);
          })
          .catch(console.error);

        axios
          .get(this.heatmap.heatmap, { responseType: "blob" })
          .then(async (response) => {
            const blob2 = new Blob([response.data], { type: "image/png" });
            this.baseheat = await this.toBase64(blob2);
          })
          .catch(console.error);
      }
      vm.$emit("type", vm.heatmap.type);
      vm.projects.forEach((p) => {
        if (p.heatmaps.includes(vm.heatmap.uid)) {
          vm.projectIds.push(p.uid);
        }
      });
      this.isLoading = false;
      this.$store.commit("setLoading", false);
    },
    getRatio() {
      this.ratio = this.$refs.vid.clientWidth / this.$refs.vid.clientHeight;
      const vm = this;
      setTimeout(() => {
        vm.videoLoaded = true;
      }, "200");
    },
    getCountry(c) {
      let country = "";

      switch (c) {
        case "":
          country = "All countries";
          break;
        case "de":
          country = "Germany";
          break;
        case "nl":
          country = "The Netherlands";
          break;
        case "es":
          country = "Spain";
          break;
        case "uk":
          country = "United Kingdom";
          break;
        case "us":
          country = "United States";
          break;
      }
      console.log(country);
      return country;
    },
    getIndustry(i) {
      let industry = "";

      switch (i) {
        case "":
          industry = "All industries";
          break;
        case "arts and entertainment":
          industry = "Arts & Entertainment";
          break;
        case "vehicles":
          industry = "Automotive & vehicles";
          break;
        case "computers electronics and technology":
          industry = "Computers, electronics & technology";
          break;
        case "e-commerce and shopping":
          industry = "E-commerce & shopping";
          break;
        case "finance":
          industry = "Finance";
          break;
        case "food and beverages":
          industry = "Food & beverages";
          break;
        case "home and garden":
          industry = "Home & garden";
          break;
        case "lifestyle":
          industry = "Lifestyle";
          break;
        case "news and media":
          industry = "News & media";
          break;
        case "sports":
          industry = "Sports";
          break;
        case "travel":
          industry = "Travel";
          break;
      }
      return industry;
    },
    getValue(v) {
      const val = Math.round(v * 100);
      return val;
    },
    getDate(d) {
      const date = d.toDate();
      return moment(date).format("DD-MM-YYYY");
    },
    getTimeAgo(t) {
      console.log(t);
      return "1hr ago ";
    },
    importAOIfromHeatmap() {
      this.importAOIs.forEach((aoi) => {
        const ref = this.$refs.heatmap;
        ref.addBox(aoi);
      });
      this.importAOI = false;
    },
    initialized(val) {
      console.log("initalized");
      this.loadedBoxes = false;
      this.loadedBoxes = val;
      this.boxes = this.heatmap.areas_of_interest;
    },
    newRow() {
      this.emails.push({ email: "" });
    },
    openHeatmap(uid) {
      this.$router.push(`/heatmap/${uid}`);
      this.boxes = [];
      this.comparison = [];
      this.$emit("navigation", 2);
      this.fetchData();
    },

    remove(i) {
      this.emails.splice(i, 1);
    },
    removeComment(uid) {
      const vm = this;
      console.log(uid);
      db.collection("heatmaps")
        .doc(this.$route.params.id)
        .collection("timeline")
        .doc(uid)
        .delete()
        .then(() => {
          vm.fetchComments();
          var ref = db.collection("heatmaps").doc(vm.$route.params.id);

          ref.update({
            timeline: firebase.firestore.FieldValue.increment(-1),
          });
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },
    refreshCompare(b) {
      this.heatmap.areas_of_interest = b;
      this.updateBoxes(b);
    },

    share(v) {
      this.heatmap.public = v;
      this.save();
    },
    save() {
      const vm = this;
      if (!this.heatmap.public) {
        this.copied = false;
      }
      db.collection("heatmaps")
        .doc(vm.heatmap.uid)
        .update({
          title: vm.heatmap.title,
          tags: vm.heatmap.tags,
          application: vm.heatmap.application,
          device: vm.heatmap.device,
          public: vm.heatmap.public,
        })
        .then(() => {
          vm.projects.forEach((p) => {
            console.log(p);
            console.log(vm.projectIds);
            if (
              vm.projectIds.includes(p.uid) &&
              !p.heatmaps.includes(vm.heatmap.uid)
            ) {
              console.log("add heatmap");
              var projectRef = db.collection("projects").doc(p.uid);
              projectRef.update({
                heatmaps: firebase.firestore.FieldValue.arrayUnion(
                  `${vm.heatmap.uid}`
                ),
              });
            } else if (
              !vm.projectIds.includes(p.uid) &&
              p.heatmaps.includes(vm.heatmap.uid)
            ) {
              console.log("remove heatmap");
              var ref = db.collection("projects").doc(p.uid);
              ref.update({
                heatmaps: firebase.firestore.FieldValue.arrayRemove(
                  `${vm.heatmap.uid}`
                ),
              });
            }
          });
        })
        .then(() => {
          console.log("Document successfully updated!");
          vm.$emit("snackbar", { text: "Data saved", type: "success" });
          vm.$store.dispatch("fetchProjects");
        });
    },
    setDialogDelete(val) {
      this.dialogDelete = val;
    },
    setDialogImport(v) {
      this.importAOI = v;
    },
    selectAOI(i) {
      const child = this.$refs.heatmap;
      child.makeBoxActive(i);
    },
    selectBox(val) {
      this.activeBoxIndex = val;
    },
    setnavigation(val) {
      this.$emit("navigation", val);
    },
    setAnalytics(v) {
      console.log(v);
      this.analytics = v;
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    updateBoxes(val) {
      console.log(val);
      this.isBoxesUpdating = true;
      this.activeBoxIndex = null;
      const vm = this;
      var heatRef = db.collection("heatmaps").doc(vm.heatmap.uid);
      if (this.loadedBoxes) {
        this.navigation = "Areas of interest";
      }
      heatRef
        .update({
          areas_of_interest: val,
        })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
      this.boxes = val;
      this.isBoxesUpdating = false;
    },
    updateAnalytics(v) {
      if (v === "Attention" && this.heatmap.logo_tracking_status === "done") {
        console.log(v);
        this.variant = "Brand detection";
      }
      if (v === "Attention" && this.heatmap.logo_tracking_status === "done") {
        this.metric = "brand_exposure";
      } else if (v === "Clarity") {
        this.metric = "focus";
      }
    },
    timeUpdate(t) {
      const video = this.$refs.vid;
      this.currentTime = video.currentTime;
      this.videoDuration = video.duration;
    },
    async updateName(val) {
      if (val.id) {
        // const update = await API.test.updateAOI(
        //   this.$route.params.heatmapId,
        //   val
        // );
      } else {
        // console.log("updating existing");
        // const result = await API.test.getPrediction(
        //   this.$route.params.heatmapId
        // );
        // const box = result.areas_of_interest.find(
        //   (el) =>
        //     Math.round(el.top) === Math.round(val.top) &&
        //     Math.round(el.left) === Math.round(val.left)
        // );
        // const updated = {
        //   width: Math.round(val.width),
        //   height: Math.round(val.height),
        //   top: Math.round(val.top),
        //   left: Math.round(val.left),
        //   id: box.id,
        //   name: val.name,
        // };
        // const update = await API.test.updateAOI(
        //   this.$route.params.heatmapId,
        //   updated
        // );
        // console.log(update);
      }
      console.log(val);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
