<template>
  <div class="pa-10" v-if="workspace">
    <div class="mb-4">
      <div class="d-flex align-center mb-4">
        <v-btn
          icon="mdi-chevron-left"
          flat
          class="mr-4"
          variant="tonal"
          density="compact"
          @click="$router.push('/superadmin/workspaces')"
        ></v-btn>
        <h1>{{ workspace.name }}</h1>
      </div>
      <v-tabs v-model="tab">
        <v-tab value="general">General</v-tab>
        <v-tab value="credits">Credits</v-tab>
        <v-tab value="subscription">Subscription</v-tab>
        <v-tab value="access">Access</v-tab>
        <v-tab value="api">API</v-tab>

        <v-spacer></v-spacer>
        <v-btn
          v-if="changes"
          @click="$store.dispatch('updateAdminWorkspace')"
          flat
          color="primary"
          >Save</v-btn
        >
      </v-tabs>
      <v-divider></v-divider>
    </div>

    <v-tabs-window v-model="tab">
      <v-tabs-window-item value="general" style="max-width: 300px">
        <div>
          <v-text-field
            variant="solo"
            flat
            background-color="white"
            label="Name"
            hide-details
            class="mb-3"
            v-model="workspace.name"
          ></v-text-field>
          <v-select
            :items="workspace.members_data"
            item-title="email"
            item-value="uid"
            hint="Important: Make sure that the new admin has a subscription in stripe."
            label="Workspace admin"
            variant="solo"
            flat
            v-model="workspace.admin"
          ></v-select>
          <div class="d-flex flex-column" style="gap: 10px">
            <p style="font-size: 10pt">Members:</p>

            <v-card
              v-for="m in workspace.members_data"
              :key="m.uid"
              flat
              width="100%"
              class="pa-4 align-center d-flex justify-space-between"
              ><div>
                <p>{{ m.email }}</p>
                <p class="mb-0 pb-0" style="font-size: 10pt">{{ m.role }}</p>
              </div>
              <v-btn v-if="m.role != 'admin'" icon variant="text"
                ><v-icon size="16">mdi-delete</v-icon></v-btn
              ></v-card
            >
          </div>
        </div>
      </v-tabs-window-item>

      <v-tabs-window-item value="credits">
        <div
          style="
            display: grid;
            grid-template-columns: 1fr 2fr;
            max-width: 800px;
            gap: 20px;
          "
        >
          <div style="max-width: 300px">
            <p class="mb-3" style="font-size: 10pt">Credits left:</p>
            <div class="grid">
              <v-text-field
                variant="solo"
                flat
                hide-details
                class="mb-3"
                background-color="white"
                label="Image"
                type="number"
                v-model.number="workspace.imageCredits"
              ></v-text-field>

              <v-text-field
                variant="solo"
                flat
                hide-details
                class="mb-3"
                background-color="white"
                label="Video"
                type="number"
                v-model.number="workspace.videoCredits"
              ></v-text-field>
            </div>
            <div class="d-flex justify-space-between align-center">
              <p style="font-size: 10pt">Customize monthly credits:</p>
              <v-switch
                color="primary"
                @update:modelValue="customCredits"
                :model-value="customCreditsVal"
                hide-details
              ></v-switch>
            </div>
            <div class="grid" v-if="customCreditsVal">
              <v-text-field
                variant="solo"
                flat
                hide-details
                class="mb-3"
                background-color="white"
                label="Image"
                type="number"
                v-model.number="workspace.custom_image_credits"
              ></v-text-field>

              <v-text-field
                variant="solo"
                type="number"
                flat
                hide-details
                class="mb-3"
                background-color="white"
                label="Video"
                v-model.number="workspace.custom_video_credits"
              ></v-text-field>
            </div>
          </div>
          <div v-if="workspace.stats">
            <p class="mb-3" style="font-size: 10pt">Usage:</p>
            <usage-chart :stats="workspace.stats" />
          </div>
        </div>
      </v-tabs-window-item>

      <v-tabs-window-item value="subscription" style="max-width: 300px">
        <v-text-field
          variant="solo"
          flat
          hide-details
          type="number"
          class="mb-3"
          background-color="white"
          label="MRR"
          v-model.number="workspace.value"
        ></v-text-field>
      </v-tabs-window-item>

      <v-tabs-window-item value="access" style="max-width: 300px">
        <v-select
          :items="features"
          v-model="workspace.access"
          multiple
          variant="solo"
          flat
          bg-color="white"
          label="Access to features"
        ></v-select>
      </v-tabs-window-item>

      <v-tabs-window-item value="api" style="max-width: 300px">
        <div class="d-flex align-center justify-space-between">
          <p style="font-size: 10pt">API Access:</p>
          <v-switch
            class="ma-0 pa-0"
            dense
            color="primary"
            hide-details=""
            @update:modelValue="updateAPI"
            :model-value="typeof workspace.api_key === 'string'"
          ></v-switch>
        </div>
        <div v-if="typeof workspace.api_key === 'string'">
          <v-text-field
            append-inner-icon="mdi-content-copy"
            variant="solo"
            flat
            @click:append-inner="copy"
            readonly
            v-if="workspace.api_key"
            v-model="workspace.api_key"
            label="API key"
          ></v-text-field>
          <v-select
            variant="solo"
            flat
            label="Tier"
            :items="[1, 2, 3]"
            v-model="workspace.api_tier"
          ></v-select>
          <p style="font-size: 10pt" class="mb-3">Styling branded page:</p>
          <v-text-field
            variant="solo"
            flat
            readonly
            v-model="workspace.branding.background"
            label="Background"
          ></v-text-field>
          <v-text-field
            variant="solo"
            flat
            readonly
            v-model="workspace.branding.bar"
            label="Bar"
          ></v-text-field>
          <v-text-field
            variant="solo"
            flat
            readonly
            v-model="workspace.branding.button"
            label="Button"
          ></v-text-field>
          <v-text-field
            variant="solo"
            flat
            readonly
            v-model="workspace.branding.menu"
            label="Menu"
          ></v-text-field>
          <v-text-field
            variant="solo"
            flat
            readonly
            v-model="workspace.branding.logo"
            label="Logo"
          ></v-text-field>
        </div>
      </v-tabs-window-item>
    </v-tabs-window>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import UsageChart from "./usageChart.vue";

export default {
  components: {
    UsageChart,
  },
  data() {
    return {
      changes: false,
      features: [
        "audits",
        "object_recognition",
        "youtube_thumbnails",
        "reports",
      ],
      tab: "details",
    };
  },
  watch: {
    workspace: {
      handler: function (val) {
        if (!this.loading && this.workspace) {
          this.changes = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      workspace: (state) => state.admin.workspace,
      applications: (state) => state.defaults.defaults.heatmap.types,
    }),
    customCreditsVal() {
      return this.workspace.hasOwnProperty("custom_video_credits");
    },
  },
  async mounted() {
    const res = await this.$store.dispatch(
      "fetchAdminWorkspace",
      this.$route.params.id
    );
  },
  methods: {
    addSlide() {
      this.workspace.slides.push({
        title: null,
        id: null,
        application: null,
      });
    },
    copy() {
      navigator.clipboard.writeText(this.workspace.api_key).then(
        this.$store.commit("setSnackbar", {
          text: "API Key copied to clipboard",
          timeout: 2000,
          show: true,
        })
      );
    },
    customCredits(val) {
      console.log(val);
      if (val) {
        this.workspace.custom_image_credits = 1500;
        this.workspace.custom_video_credits = 20;
      } else {
        delete this.workspace.custom_image_credits;
        delete this.workspace.custom_video_credits;
      }
    },
    openTemplate(id) {
      let url = `https://docs.google.com/presentation/d/${id}/edit#slide=id.p`;
      window.open(url, "_blank");
    },
    updateAPI(v) {
      let api_key = uuidv4();
      this.changes = true;
      if (!v) {
        delete this.workspace.api_key;
      } else {
        this.workspace.api_key = api_key;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
}
</style>
