import firebase from "firebase/";
// import { db } from "@/firebase";

const models = {
  state: {
    models: [],
  },
  mutations: {
    setUserModels(state, val) {
      state.models = val;
    },
  },
  actions: {
    fetchModels({ commit, rootState }) {
      return new Promise(async (resolve) => {
        var getModels = firebase
          .app()
          .functions("europe-west1")
          .httpsCallable("getModels");
        const res = await getModels();
        commit("setUserModels", res.data);
        console.log(res.data);
        resolve();
      });
    },
  },
  getters: {},
};

export default models;
