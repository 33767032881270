<template>
  <div>
    <SlidesDialog @close="dialog = false" :dialog="dialog" :slide="slide" />
    <div>
      <div class="bg-smoke align-center d-flex py-2 px-4" style="height: 72px">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Search"
          bg-color="smoke"
          variant="solo"
          flat
          density="compact"
          hide-details
        ></v-text-field>
        <v-btn
          color="primary"
          flat
          @click="
            slide = {};
            dialog = true;
          "
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </div>
      <v-divider></v-divider>
    </div>
    <div class="users">
      <v-card class="table" flat>
        <v-data-table
          hover
          @click:row="(e, r) => openItem(r.item)"
          dense
          show-select
          height="calc(100vh - 72px - 64px)"
          fixed-header=""
          :headers="headers"
          :items="slides"
          :search="search"
          :items-per-page="100"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn @click.stop="open(item)" icon flat density="comfortable">
              <v-icon size="15">mdi-open-in-new</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SlidesDialog from "./SlidesDialog.vue";

export default {
  components: {
    SlidesDialog,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      slides: (state) => state.admin.slides,
    }),
  },
  data() {
    return {
      dialog: false,
      expanded: [],
      headers: [
        {
          title: "Title",
          align: "start",
          sortable: false,
          key: "title",
        },
        { title: "Orientation", key: "orientation" },
        { title: "Access", key: "access" },
        { title: "Application", key: "application" },
        { title: "", key: "actions" },
      ],
      search: "",
      slide: null,
    };
  },
  methods: {
    open(i) {
      let url = `https://docs.google.com/presentation/d/${i.id}`;
      window.open(url, "_blank");
    },
    openItem(v) {
      this.dialog = true;
      this.slide = v;
      console.log(v);
    },
  },
};
</script>
