<template>
  <div class="text-center">
    <v-dialog opacity="0.75" max-width="1000px" v-model="dialog">
      <template v-slot:activator="{ props: activatorProps }">
        <v-tooltip text="Let Brainsight Co-Pilot analyse your design.">
          <template v-slot:activator="{ props }">
            <div v-bind="props">
              <v-btn v-bind="activatorProps" elevation="0" color="white" block>
                <!-- <v-badge content="beta" color="primary" floating> -->
                <!-- <v-img
                    width="18"
                    :src="require('@/assets/logo-dark.svg')"
                  ></v-img> -->
                <v-icon>mdi-creation</v-icon>
                <!-- </v-badge> -->
              </v-btn>
            </div>
          </template>
        </v-tooltip>
      </template>

      <template v-slot:default="{ isActive }">
        <v-card
          style="
            width: 90%;
            font-size: 10pt;
            margin: auto;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          "
          class="px-2 pt-2 pb-4 bg-primary text-center"
        >
          <span><b>Audit</b></span></v-card
        >

        <v-card flat class="px-10 py-6" max-width="1000px">
          <div class="mt-4 mb-6 d-flex align-center justify-space-between">
            <div>
              <h2>UX Audit</h2>
              <p style="font-size: 10pt; max-width: 70%">
                An automated UX audit by Brainsight Co-Pilot, based on the
                website and the heatmap data above, predicting the first 5
                seconds of attention.
              </p>
            </div>
            <div class="d-flex align-center" style="gap: 10px">
              <v-menu v-if="heatmap.audit">
                <template v-slot:activator="{ props }">
                  <v-btn
                    :loading="exporting.pdf || exporting.pptx"
                    color="primary"
                    flat
                    v-bind="props"
                    prepend-icon="mdi-download"
                  >
                    Download
                  </v-btn>
                </template>
                <v-list>
                  <div
                    v-for="s in slides.filter(
                      (s) =>
                        s.orientation === orientation &&
                        s.application.includes(heatmap.application)
                    )"
                    :key="s.id"
                  >
                    <v-list-item link @click="exportFile('pdf', s)">
                      <v-list-item-title
                        ><b>PDF -</b> {{ s.title }}</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item link @click="exportFile('pptx', s)">
                      <v-list-item-title
                        ><b>PPTX -</b> {{ s.title }}</v-list-item-title
                      >
                    </v-list-item>
                  </div>
                </v-list>
              </v-menu>
              <v-btn
                icon="mdi-close"
                @click="isActive.value = false"
                variant="tonal"
                size="small"
                v-if="heatmap && heatmap.audit"
              ></v-btn>
              <div v-else>
                <v-btn
                  variant="tonal"
                  size="small"
                  :loading="processing"
                  @click="request"
                  >Request Analysis</v-btn
                >
              </div>
            </div>
          </div>

          <div v-if="heatmap && heatmap.audit">
            <div>
              <v-divider></v-divider>
              <h3 class="mt-10">1. Associations</h3>
              <p class="mb-4" style="font-size: 10pt">
                Associations related to this page after looking for maximum of 5
                seconds.
              </p>
              <div class="d-flex flex-wrap" style="gap: 10px">
                <v-chip
                  size="large"
                  color="primary"
                  v-for="(i, index) in heatmap.audit.associations"
                  :key="index"
                  >{{ i.association }}</v-chip
                >
              </div>
            </div>

            <v-divider class="my-10"></v-divider>
            <h3 class="mt-6">2. Clarity of the website</h3>
            <p class="mb-4" style="font-size: 10pt">
              Benchmarked metrics to determine the cognitive ease for this
              website.
            </p>
            <div
              style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px"
            >
              <div>
                <v-card class="pa-6 bg-accent">
                  <div class="d-flex">
                    <div>
                      <p class="mb-1">Focus</p>
                      <p style="font-size: 10pt">
                        Focus indicates the relative size and amount of the
                        attention grabbing elements.
                      </p>
                    </div>
                    <v-progress-circular
                      :model-value="100 - heatmap.metrics.percentile_area"
                      :size="100"
                      :width="11"
                      :color="getColor(100 - heatmap.metrics.percentile_area)"
                    >
                      <template v-slot:default>
                        <b> {{ 100 - heatmap.metrics.percentile_area }}</b>
                      </template>
                    </v-progress-circular>
                  </div>

                  <v-img
                    ref="imgRef"
                    @load="loadOrientation"
                    :src="heatmap.peekthrough"
                  ></v-img>
                </v-card>
                <v-card
                  class="pa-4 bg-smoke"
                  flat
                  style="
                    font-size: 10pt;

                    z-index: -1;
                    width: 95%;
                    margin: auto;
                  "
                >
                  <p v-if="100 - heatmap.metrics.percentile_area >= 65">
                    The Focus score is already better than our target (65 or
                    higher). Therefore there are no suggestions.
                  </p>
                  <div v-else>
                    <p>
                      There are multiple elements that grab attention, and of
                      which some are relatively big.
                    </p>
                    <p class="mt-2">
                      <v-icon size="14" class="mr-1">mdi-lightbulb</v-icon>
                      <i>
                        Consider to reduce the amount of attention grabbing
                        elements, specifically elements that are distracting
                        from the user's goal.</i
                      >
                    </p>
                  </div>
                </v-card>
              </div>

              <div>
                <v-card class="pa-6 bg-accent">
                  <div class="d-flex">
                    <div>
                      <p class="mb-1">Proximity</p>
                      <p style="font-size: 10pt">
                        Proximity indicates the closeness of attention grabbing
                        elements within the creative.
                      </p>
                    </div>
                    <v-progress-circular
                      :model-value="heatmap.metrics.percentile_distances"
                      :size="100"
                      :width="11"
                      :color="
                        getColor(100 - heatmap.metrics.percentile_distances)
                      "
                    >
                      <template v-slot:default>
                        <b> {{ heatmap.metrics.percentile_distances }}</b>
                      </template>
                    </v-progress-circular>
                  </div>
                  <v-img :src="heatmap.gazeplot"></v-img>
                </v-card>

                <v-card
                  class="pa-4 bg-smoke"
                  flat
                  style="
                    font-size: 10pt;

                    z-index: -1;
                    width: 95%;
                    margin: auto;
                  "
                >
                  <p v-if="heatmap.metrics.percentile_distances <= 35">
                    The Proximity score is already better than our target (65 or
                    higher). Therefore there are no suggestions.
                  </p>

                  <div v-else>
                    <p>
                      There are multiple attention grabbing elements and they
                      are distant from each other.
                    </p>
                    <p class="mt-2">
                      <v-icon size="14" class="mr-1">mdi-lightbulb</v-icon>
                      <i>
                        Consider to move the attention grabbing elements closer,
                        and/or remove distracting elements.</i
                      >
                    </p>
                  </div>
                </v-card>
              </div>
            </div>

            <v-divider class="my-10"></v-divider>
            <h3 class="mt-6">3. Goal oriented scores</h3>
            <p class="mb-4" style="font-size: 10pt; max-width: 70%">
              Based on scaled questions to valuate the key components of a
              website. Remember, they are answered based on the first 5 seconds.
            </p>
            <div
              style="
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 20px;
              "
              class="mt-6"
            >
              <v-card flat class="pa-4" style="border: 1px solid #ebebef">
                <div class="mb-4">
                  <p class="mb-1">
                    <span style="font-size: 30pt; font-weight: 600">{{
                      heatmap.audit.offer.rating
                    }}</span
                    ><span>/10</span>
                  </p>
                  <v-progress-linear
                    :color="getColor(heatmap.audit.offer.rating * 10)"
                    :model-value="heatmap.audit.offer.rating * 10"
                    rounded
                  ></v-progress-linear>
                </div>
                <div>
                  <div class="mb-1 d-flex align-center">
                    <span><b>What is the page about?</b></span>
                    <v-tooltip text="Tooltip">
                      <template v-slot:activator="{ props }">
                        <v-icon
                          color="grey"
                          class="ml-1"
                          size="16"
                          v-bind="props"
                          >mdi-information</v-icon
                        >
                      </template>
                    </v-tooltip>
                  </div>

                  <p style="font-size: 10pt">
                    {{ heatmap.audit.offer.reasoning }}
                  </p>
                </div>
                <div
                  style="position: relative"
                  v-if="heatmap.audit.offer.rating <= 6"
                >
                  <v-card
                    flat
                    class="mt-2 bg-smoke pa-2"
                    style="font-size: 10pt"
                  >
                    <v-icon size="14" class="mr-1">mdi-lightbulb</v-icon>
                    <i> {{ heatmap.audit.offer.suggestion }}</i>
                  </v-card>
                  <div
                    style="
                      position: absolute;
                      left: 10px;
                      top: -5px;
                      z-index: 99;
                      width: 0;
                      height: 0;
                      border-left: 10px solid transparent;
                      border-right: 10px solid transparent;
                      border-bottom: 10px solid #ebebef;
                    "
                  ></div>
                </div>
              </v-card>

              <v-card flat class="pa-4" style="border: 1px solid #ebebef">
                <div class="mb-4">
                  <p class="mb-1">
                    <span style="font-size: 30pt; font-weight: 600">{{
                      heatmap.audit.goNext.rating
                    }}</span
                    ><span>/10</span>
                  </p>
                  <v-progress-linear
                    :color="getColor(heatmap.audit.goNext.rating * 10)"
                    :model-value="heatmap.audit.goNext.rating * 10"
                    rounded=""
                  ></v-progress-linear>
                </div>
                <div>
                  <p class="mb-1"><b>Where should I go next?</b></p>
                  <p style="font-size: 10pt">
                    {{ heatmap.audit.goNext.reasoning }}
                  </p>
                </div>
                <div
                  style="position: relative"
                  v-if="heatmap.audit.goNext.rating <= 6"
                >
                  <v-card
                    flat
                    class="mt-2 bg-smoke pa-2"
                    style="font-size: 10pt"
                  >
                    <v-icon size="14" class="mr-1">mdi-lightbulb</v-icon>
                    <i> {{ heatmap.audit.goNext.suggestion }}</i>
                  </v-card>
                  <div
                    style="
                      position: absolute;
                      left: 10px;
                      top: -5px;
                      z-index: 99;
                      width: 0;
                      height: 0;
                      border-left: 10px solid transparent;
                      border-right: 10px solid transparent;
                      border-bottom: 10px solid #ebebef;
                    "
                  ></div>
                </div>
              </v-card>

              <v-card flat class="pa-4" style="border: 1px solid #ebebef">
                <div class="mb-4">
                  <p class="mb-1">
                    <span style="font-size: 30pt; font-weight: 600">{{
                      heatmap.audit.why.rating
                    }}</span
                    ><span>/10</span>
                  </p>
                  <v-progress-linear
                    :color="getColor(heatmap.audit.why.rating * 10)"
                    :model-value="heatmap.audit.why.rating * 10"
                    rounded=""
                  ></v-progress-linear>
                </div>
                <div>
                  <p class="mb-1"><b>What makes me want to stay?</b></p>
                  <p class="mb-0 pb-0" style="font-size: 10pt">
                    {{ heatmap.audit.why.reasoning }}
                  </p>
                </div>
                <div
                  style="position: relative"
                  v-if="heatmap.audit.why.rating <= 6"
                >
                  <v-card
                    flat
                    class="mt-2 bg-smoke pa-2"
                    style="font-size: 10pt"
                  >
                    <v-icon size="14" class="mr-1">mdi-lightbulb</v-icon>
                    <i> {{ heatmap.audit.why.suggestion }}</i>
                  </v-card>
                  <div
                    style="
                      position: absolute;
                      left: 10px;
                      top: -5px;
                      z-index: 99;
                      width: 0;
                      height: 0;
                      border-left: 10px solid transparent;
                      border-right: 10px solid transparent;
                      border-bottom: 10px solid #ebebef;
                    "
                  ></div>
                </div>
              </v-card>

              <v-card flat class="pa-4" style="border: 1px solid #ebebef">
                <div class="mb-4">
                  <p class="mb-1">
                    <span style="font-size: 30pt; font-weight: 600">{{
                      heatmap.audit.brand.rating
                    }}</span
                    ><span>/10</span>
                  </p>
                  <v-progress-linear
                    :color="getColor(heatmap.audit.brand.rating * 10)"
                    :model-value="heatmap.audit.brand.rating * 10"
                    rounded=""
                  ></v-progress-linear>
                </div>
                <div>
                  <p class="mb-1">
                    <b>Who is behind the website?</b>
                  </p>
                  <p style="font-size: 10pt">
                    {{ heatmap.audit.brand.reasoning }}
                  </p>
                </div>
                <div
                  style="position: relative"
                  v-if="heatmap.audit.brand.rating <= 6"
                >
                  <v-card
                    flat
                    class="mt-2 bg-smoke pa-2"
                    style="font-size: 10pt"
                  >
                    <v-icon size="14" class="mr-1">mdi-lightbulb</v-icon>
                    <i> {{ heatmap.audit.brand.suggestion }}</i>
                  </v-card>
                  <div
                    style="
                      position: absolute;
                      left: 10px;
                      top: -5px;
                      z-index: 99;
                      width: 0;
                      height: 0;
                      border-left: 10px solid transparent;
                      border-right: 10px solid transparent;
                      border-bottom: 10px solid #ebebef;
                    "
                  ></div>
                </div>
              </v-card>

              <v-card flat class="pa-4" style="border: 1px solid #ebebef">
                <div class="mb-4">
                  <p class="mb-1">
                    <span style="font-size: 30pt; font-weight: 600">{{
                      heatmap.audit.readability.rating
                    }}</span
                    ><span>/10</span>
                  </p>
                  <v-progress-linear
                    :color="getColor(heatmap.audit.readability.rating * 10)"
                    :model-value="heatmap.audit.readability.rating * 10"
                    rounded=""
                  ></v-progress-linear>
                </div>
                <div>
                  <p class="mb-1"><b>Readability</b></p>
                  <p style="font-size: 10pt">
                    {{ heatmap.audit.readability.reasoning }}
                  </p>
                </div>
                <div
                  style="position: relative"
                  v-if="heatmap.audit.readability.rating <= 6"
                >
                  <v-card
                    flat
                    class="mt-2 bg-smoke pa-2"
                    style="font-size: 10pt"
                  >
                    <v-icon size="14" class="mr-1">mdi-lightbulb</v-icon>
                    <i> {{ heatmap.audit.readability.suggestion }}</i>
                  </v-card>
                  <div
                    style="
                      position: absolute;
                      left: 10px;
                      top: -5px;
                      z-index: 99;
                      width: 0;
                      height: 0;
                      border-left: 10px solid transparent;
                      border-right: 10px solid transparent;
                      border-bottom: 10px solid #ebebef;
                    "
                  ></div>
                </div>
              </v-card>
              <v-card flat class="pa-4" style="border: 1px solid #ebebef">
                <div class="mb-4">
                  <p class="mb-1">
                    <span style="font-size: 30pt; font-weight: 600">{{
                      heatmap.audit.trustworthiness.rating
                    }}</span
                    ><span>/10</span>
                  </p>
                  <v-progress-linear
                    :color="getColor(heatmap.audit.trustworthiness.rating * 10)"
                    :model-value="heatmap.audit.trustworthiness.rating * 10"
                    rounded=""
                  ></v-progress-linear>
                </div>
                <div>
                  <p class="mb-1"><b>Trustworthiness</b></p>
                  <p style="font-size: 10pt">
                    {{ heatmap.audit.trustworthiness.reasoning }}
                  </p>
                </div>
                <div
                  style="position: relative"
                  v-if="heatmap.audit.trustworthiness.rating <= 6"
                >
                  <v-card
                    flat
                    class="mt-2 bg-smoke pa-2"
                    style="font-size: 10pt"
                  >
                    <v-icon size="14" class="mr-1">mdi-lightbulb</v-icon>
                    <i> {{ heatmap.audit.trustworthiness.suggestion }}</i>
                  </v-card>
                  <div
                    style="
                      position: absolute;
                      left: 10px;
                      top: -5px;
                      z-index: 99;
                      width: 0;
                      height: 0;
                      border-left: 10px solid transparent;
                      border-right: 10px solid transparent;
                      border-bottom: 10px solid #ebebef;
                    "
                  ></div>
                </div>
              </v-card>
            </div>
            <v-divider class="mt-10"></v-divider>
            <div class="my-10 d-flex justify-center">
              <div style="width: 400px">
                <div class="d-flex justify-space-between align-center mb-2">
                  <img
                    class="mb-2"
                    src="@/assets/logo-dark.svg"
                    style="width: 30px"
                    alt=""
                  />
                  <span>Help us improve this feature</span>
                </div>

                <v-textarea
                  variant="solo"
                  flat
                  bg-color="smoke"
                  label="Feedback"
                  hide-details=""
                  class="mb-2"
                  v-model="feedback"
                ></v-textarea>
                <v-btn
                  :disabled="!feedback"
                  color="primary"
                  flat
                  variant="tonal"
                  block=""
                  @click="sendFeedback"
                  >Send feedback</v-btn
                >
                <div v-if="feedback_sent">Your feedback is sent. Thanks!</div>
              </div>
            </div>

            <!-- <v-divider class="my-10"></v-divider>
            <h3 class="mt-6">Findings & suggestions</h3>
            <p class="mb-4" style="font-size: 10pt; max-width: 70%">
              Based on scaled questions to valuate the key components of a
              website. Remember, they are answered based on the first 5 seconds.
            </p> -->
          </div>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { db } from "@/firebase";
import { mapState } from "vuex";
const axios = require("axios");

export default {
  props: {
    heatmap: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState({
      slides: (state) => state.workspace.slides,
    }),
  },
  data() {
    return {
      dialog: false,
      exporting: {
        pdf: false,
        pptx: false,
      },
      feedback: "",
      feedback_sent: false,
      orientation: "landscape",
      processing: false,
      show: false,
    };
  },

  methods: {
    loadOrientation() {
      let r = this.$refs.imgRef;
      let o = r.naturalHeight / r.naturalWidth;
      if (o > 1) {
        this.orientation = "portrait";
      } else {
        this.orientation = "landscape";
      }
    },
    async exportFile(type, template) {
      this.exporting[type] = true;
      let url;
      if (
        this.heatmap.audit_exports &&
        this.heatmap.audit_exports[`${template.id}_${type}`]
      ) {
        url = this.heatmap.audit_exports[`${template.id}_${type}`];
      } else {
        var powerpoint = firebase
          .app()
          .functions("europe-west1")
          .httpsCallable("powerpoint");
        const res = await powerpoint({
          uid: this.$route.params.id,
          type: type,
          template: template,
        });
        console.log(res);

        url = res.data;
      }
      console.log(url);
      this.heatmap[`audit_${type}`] = url;

      const response = await axios.get(url, { responseType: "blob" });
      let mimeType =
        type && type === "pptx"
          ? "application/vnd.openxmlformats-officedocument.presentationml.presentation"
          : "application/pdf";
      const blob = new Blob([response.data], { type: mimeType });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = this.heatmap.title + "." + type;
      link.click();
      URL.revokeObjectURL(link.href);
      this.exporting[type] = false;
      this.$store.commit("setSnackbar", {
        text: "File downloaded!",
        timeout: 3000,
        show: true,
      });
    },
    getColor(value) {
      if (value < 40) {
        return "error";
      } else if (value < 60) {
        return "info";
      } else {
        return "success";
      }
    },
    async request() {
      this.processing = true;
      var auditRequestUX = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("auditRequestUX");
      const res = await auditRequestUX(this.heatmap.uid);
      console.log(res);
      this.$emit("ready");
      this.processing = false;
    },
    async sendFeedback() {
      const doc = await db.collection("mail").add({
        to: "support@brainsight.app",
        from: "Brainsight <info@brainsight.app>",
        message: {
          subject: "Beta feedback - UX Audit",
          html: `${this.$store.state.users.userProfile.email} - ${this.feedback}`,
        },
      });
      console.log(doc.id);
      this.feedback_sent = true;
    },
  },
};
</script>
