import { createWebHistory, createRouter } from "vue-router";
import { auth } from "@/firebase.js";

// Signup etc
import Login from "@/views/Login.vue";
import Signup from "@/views/Signup.vue";
import Verify from "@/views/Verify.vue";
import Reset from "@/views/Reset.vue";
import Followup from "@/views/Followup.vue";
import Invited from "@/views/Invited.vue";

// Dashboard
import Home from "../views/Home";
import Dashboard from "@/views/Home/Dashboard";
import Heatmaps from "@/views/Home/Heatmaps";
import Projects from "@/views/Home/Projects";
import Project from "@/views/Home/Project";

// Pages
import Workspace from "@/views/Workspace.vue";
import Invites from "@/views/Invites.vue";
import WorkspaceUser from "@/views/WorkspaceUser.vue";
import Account from "@/views/AccountPage.vue";
import Subscription from "@/views/Subscription.vue";
import Heatmap from "@/views/Heatmap/HeatmapPage.vue";
import YoutubeThumbnail from "@/views/Tools/YoutubeThumbnail.vue";

// Reports
import Reports from "@/views/Reports";
import Report from "@/views/Reports/Report";
import DisplayReport from "@/views/Reports/Display";

// Superadmin
import Superadmin from "@/views/Superadmin";
import SuperadminDashboard from "@/views/Superadmin/Dashboard";
import SuperadminUsers from "@/views/Superadmin/Users/AdminUsers.vue";
import SuperadminWorkspaces from "@/views/Superadmin/Workspaces/AdminWorkspaces.vue";
import AdminWorkspace from "@/views/Superadmin/Workspaces/AdminWorkspace.vue";
import SuperadminSubscriptions from "@/views/Superadmin/Subscriptions/AdminSubscriptions.vue";
import SuperAdminMail from "@/views/Superadmin/Mail/index.vue";
import Mobile from "@/views/Superadmin/Mobile";
import Models from "@/views/Superadmin/Models/AdminModels.vue";
import Benchmarks from "@/views/Superadmin/Benchmarks/index.vue";
import API from "@/views/Superadmin/API/AdminAPI.vue";
import Benchmark from "@/views/Superadmin/Benchmarks/AdminBenchmark/index.vue";
import BenchmarkDashboard from "@/views/Superadmin/Benchmarks/BenchmarkDashboard";
import Slides from "@/views/Superadmin/Slides/index.vue";

// Other
import ViewOnly from "@/views/ViewOnly/ViewOnly.vue";
import EmailAction from "@/views/EmailAction.vue";
import Success from "@/views/Success.vue";
import Terms from "@/views/Docs/Terms/DocsTerms.vue";
import Privacy from "@/views/Docs/Privacy/DocsPrivacy.vue";
import Support from "@/views/Docs/Support/DocsSupport.vue";
import Label from "@/views/LabelPage.vue";
import Compare from "@/views/ComparePage.vue";
import Demo from "@/views/DemoFrame.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
      overlay: true,
    },
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          requiresAuth: true,
          overlay: true,
        },
      },
      {
        path: "/heatmaps",
        component: Heatmaps,
        name: "Heatmaps",
        meta: {
          requiresAuth: true,
          overlay: true,
        },
      },
      {
        path: "/projects",
        component: Projects,
        name: "Projects",
        meta: {
          requiresAuth: true,
          overlay: true,
        },
      },
      {
        path: "/project/:id",
        component: Project,
        name: "Project",
        meta: {
          requiresAuth: true,
          overlay: true,
        },
      },
    ],
  },
  {
    path: "/superadmin",
    name: "Superadmin",
    component: Superadmin,
    meta: {
      requiresAuth: true,
      requiresSuperadmin: true,
      overlay: true,
    },
    redirect: "/superadmin/dashboard",
    children: [
      {
        path: "/superadmin/dashboard",
        component: SuperadminDashboard,
        name: "Superadmin Dashboard",
      },
      {
        path: "/superadmin/users",
        component: SuperadminUsers,
        name: "Superadmin Users",
      },
      {
        path: "/superadmin/workspaces",
        component: SuperadminWorkspaces,
        name: "Superadmin Workspaces",
      },
      {
        path: "/superadmin/workspace/:id",
        component: AdminWorkspace,
        name: "Superadmin Workspace",
      },
      {
        path: "/superadmin/subscriptions",
        component: SuperadminSubscriptions,
        name: "Superadmin Subscriptions",
      },
      {
        path: "/superadmin/models",
        component: Models,
        name: "Superadmin Models",
      },
      {
        path: "/superadmin/mail",
        component: SuperAdminMail,
        name: "Superadmin Mail",
      },
      {
        path: "/superadmin/benchmarks",
        component: Benchmarks,
        name: "Superadmin Benchmarks",
        redirect: "/superadmin/benchmark/dashboard",
        children: [
          {
            path: "/superadmin/benchmark/dashboard",
            component: BenchmarkDashboard,
            name: "Benchmark Dashboard",
          },
          {
            path: "/superadmin/benchmark/:id",
            component: Benchmark,
            name: "Benchmark",
          },
        ],
      },
      {
        path: "/superadmin/api",
        component: API,
        name: "Superadmin API",
      },
      {
        path: "/superadmin/mobile",
        component: Mobile,
        name: "Superadmin Mobile",
      },
      {
        path: "/superadmin/slides",
        component: Slides,
        name: "Superadmin Slides",
      },
    ],
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    meta: {
      requiresAuth: true,
      overlay: true,
    },
  },
  {
    path: "/report/:id",
    name: "Report",
    component: Report,
    meta: {
      requiresAuth: true,
      overlay: true,
    },
  },
  {
    path: "/label/:id",
    name: "Label",
    component: Label,
    meta: {
      requiresAuth: true,
      overlay: true,
    },
  },
  {
    path: "/youtube",
    name: "YouTube Thumbnail Context Generator",
    component: YoutubeThumbnail,
    meta: {
      requiresAuth: true,
      overlay: true,
    },
  },
  {
    path: "/display-report/:id",
    name: "Display Ads Report",
    component: DisplayReport,
    meta: {
      requiresAuth: true,
      overlay: true,
    },
  },
  {
    path: "/workspace",
    name: "Workspace",
    component: WorkspaceUser,
    meta: {
      requiresAuth: true,
      overlay: true,
    },
  },

  {
    path: "/account",
    name: "Account",
    component: Account,
    meta: {
      requiresAuth: true,
      overlay: true,
    },
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: Subscription,
    meta: {
      requiresAuth: true,
      overlay: true,
    },
  },
  {
    path: "/heatmap/:id",
    name: "Heatmap",
    component: Heatmap,
    meta: {
      requiresAuth: true,
      overlay: true,
    },
  },
  {
    path: "/compare",
    name: "Compare",
    component: Compare,
    meta: {
      requiresAuth: true,
      overlay: true,
    },
  },
  {
    path: "/viewonly/:id",
    name: "View Heatmap",
    component: ViewOnly,
    meta: {
      requiresAuth: false,
      noNav: true,
      overlay: false,
    },
    alias: ["/api/:id", "/boldlens/:id"],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      noNav: true,
      overlay: true,
    },
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: {
      noNav: true,
      overlay: true,
    },
  },
  {
    path: "/reset",
    name: "Reset",
    component: Reset,
    meta: {
      noNav: true,
      overlay: true,
    },
  },
  {
    path: "/actions",
    name: "Actions",
    component: EmailAction,
    meta: {
      noNav: true,
      overlay: true,
    },
  },
  {
    path: "/verify",
    name: "Verify",
    component: Verify,
    meta: {
      noNav: true,
      overlay: true,
    },
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
    meta: {
      noNav: true,
      overlay: true,
    },
  },
  {
    path: "/privacy",
    name: "Privacy policy",
    component: Privacy,
    meta: {
      noNav: true,
      overlay: true,
    },
  },
  {
    path: "/success",
    name: "Success",
    component: Success,
    meta: {
      noNav: true,
      overlay: true,
    },
  },
  {
    path: "/invited",
    name: "Invited",
    component: Invited,
    meta: {
      noNav: true,
      requiresAuth: true,
      overlay: true,
    },
  },
  {
    path: "/add-workspace",
    name: "Add Workspace",
    component: Workspace,
    meta: {
      noNav: true,
      requiresAuth: true,
      overlay: true,
    },
  },
  {
    path: "/invites",
    name: "Invites",
    component: Invites,
    meta: {
      noNav: true,
      requiresAuth: true,
      overlay: true,
    },
  },
  {
    path: "/followup",
    name: "Follow up",
    component: Followup,
    meta: {
      noNav: true,
      requiresAuth: true,
      overlay: true,
    },
  },

  {
    path: "/support",
    component: Support,
    name: "Support",
    meta: {
      requiresAuth: true,
      overlay: true,
    },
  },
  {
    path: "/demo",
    component: Demo,
    name: "Demo",
    meta: {
      noNav: true,
      requiresAuth: false,
      overlay: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const requiresSuperadmin = to.matched.some((x) => x.meta.requiresSuperadmin);
  let superadmin = false;
  if (auth.currentUser) {
    let r = await auth.currentUser.getIdTokenResult();
    superadmin = r.claims.admin;
  }

  if (requiresAuth && !auth.currentUser && !to.path.includes("/invited")) {
    next("/login");
  } else if (requiresSuperadmin && !superadmin) {
    next("/");
  } else {
    next();
  }
});

export default router;
