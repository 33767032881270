<template>
  <v-dialog persistent :model-value="dialog" width="auto">
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn
        v-bind="activatorProps"
        elevation="0"
        color="primary"
        class="mb-2"
        block
        >Request Brand Analytics</v-btn
      >
    </template>

    <template v-slot:default="{ isActive }">
      <v-card width="400">
        <div class="pa-4 bg-primary d-flex justify-space-between align-center">
          <div>
            <p style="font-size: 14pt">Request Brand Analytics</p>
          </div>
          <v-btn
            icon="mdi-close"
            @click="isActive.value = false"
            variant="flat"
            color="primary"
          ></v-btn>
        </div>

        <v-window v-model="window" class="pa-4">
          <v-window-item :key="0" :value="0">
            <div class="mb-4">
              <p style="font-size: 10pt" class="mb-1">Pick your brand</p>
              <v-select
                variant="solo"
                flat
                label="Brand"
                v-model="brand"
                bg-color="smoke"
                hide-details
                :items="models"
                item-title="name"
                return-object
              ></v-select>
              <p
                class="mt-1"
                @click="window = 1"
                style="font-size: 9pt; cursor: pointer"
              >
                <u>My brand is not in this list</u>
              </p>
              <div v-if="brand">
                <p style="font-size: 10pt" class="mb-1 mt-4">
                  Do you want to track text as well?
                </p>
                <v-text-field
                  variant="solo"
                  flat
                  placeholder="Volkswagen volkswagen.nl"
                  label="Text"
                  v-model="text"
                  bg-color="smoke"
                  hint="You can add multiple words by just adding spaces between them (no comma's, periods etc.)"
                  persistent-hint
                  class="mb-2"
                ></v-text-field>
              </div>
            </div>
          </v-window-item>

          <v-window-item :key="1" :value="1">
            <h4>Request Brand Training</h4>
            <p style="font-size: 10pt" class="mb-2">
              We will train a custom model for your brand. Please select first
              if your logo contains just text or also an image:
            </p>
            <v-select
              variant="solo"
              flat
              label="Type"
              v-model="options"
              bg-color="smoke"
              hide-details
              :items="['Only image', 'Only text', 'Image & Text']"
            ></v-select>
            <div v-if="options && options != 'Only image'" class="mt-4">
              <p style="font-size: 10pt" class="mb-1">
                The text that your want to have tracked:
              </p>
              <v-text-field
                variant="solo"
                flat
                placeholder="Volkswagen volkswagen.nl"
                label="Text"
                v-model="text"
                bg-color="smoke"
                hint="You can add multiple words by just adding spaces between them (no comma's, periods etc.)"
                persistent-hint
                class="mb-2"
              ></v-text-field>
            </div>
          </v-window-item>

          <v-window-item :key="2" :value="2" class="pb-3">
            <h4>Done!</h4>
            <p>
              Your request has been processed.
              <span v-if="!brand && options && options != 'Only text'"
                >Since a custom model will be trained for your brand, this might
                take up to a few working days. </span
              >You will be notified automatically.
            </p>
          </v-window-item>
        </v-window>

        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-space-end">
          <v-btn v-if="window < 2" @click="request" color="primary"
            >Request</v-btn
          >
          <v-btn v-else color="primary" @click="isActive.value = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { db } from "@/firebase";

export default {
  props: ["heatmap"],
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      applications: (state) => state.defaults.defaults.heatmap.types,
      workspaces: (state) => state.admin.workspaces,
      models: (state) => state.models.models,
    }),
  },
  data() {
    return {
      brand: null,
      step: 0,
      dialog: false,
      options: null,
      text: null,
      window: 0,
    };
  },
  methods: {
    async deleteItem() {
      let r = confirm("Are you sure?");
      if (r) {
        this.$store.commit("setLoading", true);
        await db
          .collection("admin")
          .doc("slides")
          .collection("templates")
          .doc(this.slide.id)
          .delete();

        await this.$store.dispatch("fetchAdminSlides");
        this.$store.commit("setLoading", false);
        this.$emit("close");
      }
    },
    async request() {
      if (!this.brand && !this.options) {
        return alert("Please input your request first.");
      } else if (this.brand) {
        console.log("Request existing model");
        await db
          .collection("heatmaps")
          .doc(this.heatmap.uid)
          .update({
            logo_tracking_status: "processing",
            logo_tracking_video: null,
            logo_tracking_options: {
              brand: this.brand,
              type: this.options,
              text: this.text ? this.text.toLowerCase() : null,
            },
          });
        this.$store.dispatch("predict", {
          model: { ...this.brand },
          heatmap: this.heatmap,
          text: this.text ? this.text.toLowerCase() : null,
        });
      } else if (this.text && this.options === "Only text") {
        console.log("Request text");
        await db
          .collection("heatmaps")
          .doc(this.heatmap.uid)
          .update({
            logo_tracking_status: "processing",
            logo_tracking_video: null,
            logo_tracking_options: {
              brand: this.brand,
              type: this.options,
              text: this.text ? this.text.toLowerCase() : null,
            },
          });
        this.$store.dispatch("requestTextRecognition", {
          ...this.heatmap,
          text: this.text.toLowerCase(),
        });
      } else {
        console.log("Request sent");
        await db
          .collection("heatmaps")
          .doc(this.heatmap.uid)
          .update({
            logo_tracking_status: "requested",
            logo_tracking_options: {
              type: this.options,
              brand: this.brand,
              text: this.text ? this.text.toLowerCase() : null,
            },
          });
      }
      this.window = 2;
      this.$store.commit("setSnackbar", {
        text: "Brand Analytics Requested",
        timeout: 2000,
        show: true,
      });
      this.$emit("requested");
    },
  },
};
</script>
