<template>
  <div
    style="
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 5px;
      width: 242px;
    "
    :style="{
      gridTemplateColumns: gridColumns,
    }"
  >
    <v-menu bottom open-on-hover offset-y>
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" elevation="0" color="white" block
          ><v-icon size="small">mdi-download</v-icon>
        </v-btn>
      </template>

      <v-list style="font-size: 11pt">
        <v-list-item
          append-icon="mdi-download"
          class="px-6 py-2"
          @click.stop="$emit('download', 1)"
        >
          Heatmap
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          append-icon="mdi-download"
          class="px-6 py-2"
          @click.stop="$emit('download', 2)"
        >
          Peekthrough
        </v-list-item>
        <div v-if="heatmap.logo_tracking_video">
          <v-divider></v-divider>
          <v-list-item
            append-icon="mdi-download"
            class="px-6 py-2"
            @click.stop="$emit('download', 5)"
          >
            Brand Recognition
          </v-list-item>
        </div>
        <div v-if="heatmap.gazeplot">
          <v-divider></v-divider>
          <v-list-item
            append-icon="mdi-download"
            class="px-6 py-2"
            @click.stop="$emit('download', 4)"
          >
            Gazeplot
          </v-list-item>
        </div>
        <v-divider></v-divider>
        <v-list-item
          append-icon="mdi-download"
          class="px-6 py-2"
          @click.stop="$emit('download', 3)"
        >
          Original
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item
          append-icon="mdi-download"
          class="px-6 py-2"
          @click.stop="$emit('downloadAll', 1)"
        >
          Download all
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu bottom offset-y v-if="$route.name != 'View Heatmap'">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" elevation="0" color="white" block>
          <v-icon size="small">mdi-delete</v-icon>
        </v-btn>
      </template>

      <v-card class="pa-4">
        <div class="d-flex align-center">
          <h4 class="mr-4">Are you sure?</h4>
          <v-btn
            style="font-size: 10pt"
            class="mr-1"
            size="small"
            variant="text"
            >No</v-btn
          >
          <v-btn
            size="small"
            style="font-size: 10pt"
            color="primary"
            variant="flat"
            @click="$store.dispatch('deleteHeatmap', heatmap.uid)"
            >Yes</v-btn
          >
        </div>
      </v-card>
    </v-menu>

    <v-menu :close-on-content-click="false" v-model="shareMenu" bottom offset-y>
      <template v-slot:activator="{ props }">
        <v-btn
          @click="$emit('share', true)"
          v-bind="props"
          elevation="0"
          :color="
            heatmap.public && $route.name != 'View Heatmap'
              ? 'success'
              : 'white'
          "
          block
        >
          <v-icon
            :color="
              heatmap.public && $route.name != 'View Heatmap' ? 'white' : ''
            "
            size="small"
            >mdi-share</v-icon
          >
        </v-btn>
      </template>

      <v-card class="px-4 pt-2 pb-4" v-if="heatmap.public">
        <div class="py-2">
          <p style="font-size: 8pt">Shareable link:</p>
          <span class="" style="font-size: 10pt">{{
            `https://ai.brainsight.app/viewonly/${heatmap.uid}`
          }}</span>
        </div>

        <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px">
          <v-btn
            color="primary"
            size="small"
            flat
            block=""
            @click="$emit('copy')"
            >Copy</v-btn
          ><v-btn
            v-if="$route.name != 'View Heatmap'"
            @click="$emit('share', false)"
            size="small"
            variant="outlined"
            block=""
            >Unshare</v-btn
          >
        </div>
      </v-card>
    </v-menu>

    <v-menu
      :close-on-content-click="false"
      bottom
      offset-y
      v-if="userProfile && userProfile.superadmin && heatmap.type === 'video'"
    >
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" elevation="0" color="white" block>
          <v-icon size="small">mdi-watermark</v-icon>
        </v-btn>
      </template>

      <v-list density="compact">
        <v-list-subheader>Object Recognition:</v-list-subheader>
        <v-divider></v-divider>
        <v-menu location="right" max-height="200">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" style="font-size: 11pt" link>
              Use existing model
            </v-list-item>
          </template>
          <v-list density="compact">
            <v-list-item
              link
              style="font-size: 11pt"
              v-for="(item, index) in models"
              :key="index"
              :value="item.uid"
              @click="
                $store.dispatch('predict', {
                  model: item,
                  heatmap: heatmap,
                })
              "
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu location="right" max-height="200">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" style="font-size: 11pt" link>
              Use video to train existing model
            </v-list-item>
          </template>
          <v-list density="compact">
            <v-list-item
              link
              style="font-size: 11pt"
              v-for="(item, index) in models"
              :key="index"
              :value="item.uid"
              @click="
                $router.push(
                  `/superadmin/models?model=${item.uid}&heatmap=${heatmap.uid}`
                )
              "
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-list-item
          style="font-size: 11pt"
          link
          @click="
            $router.push(`/superadmin/models?new=true&heatmap=${heatmap.uid}`)
          "
        >
          Use video to train new model
        </v-list-item>
      </v-list>
    </v-menu>

    <AuditUX
      :heatmap="heatmap"
      @ready="$emit('ready')"
      v-if="
        workspace.access &&
        workspace.access.includes('audits') &&
        heatmap.type === 'image' &&
        heatmap.application === 'UX Design'
      "
    />

    <AuditCreative
      @ready="$emit('ready')"
      :heatmap="heatmap"
      :attention_score="attention_score"
      v-if="
        workspace.access &&
        workspace.access.includes('audits') &&
        heatmap.type === 'image' &&
        (heatmap.application === 'Social media' ||
          heatmap.application === '(Digital) Out Of Home' ||
          heatmap.application === 'Display Ads')
      "
    />
  </div>
</template>

<script>
import AuditCreative from "../Audit/AuditCreative.vue";
import AuditUX from "../Audit/AuditUX.vue";
import { mapState } from "vuex";

export default {
  props: {
    heatmap: {
      type: Object,
      default: null,
    },
    models: {
      type: Array,
      default: null,
    },
    userProfile: {
      type: Object,
      default: null,
    },
    attention_score: {
      type: Number,
      default: null,
    },
  },
  components: {
    AuditCreative,
    AuditUX,
  },
  data() {
    return {
      shareMenu: false,
    };
  },

  computed: {
    gridColumns() {
      let val = "1fr 1fr 1fr";

      if (this.heatmap && this.heatmap.type === "video") {
        val = val.concat(" 1fr");
      } else if (this.heatmap && this.heatmap.type === "image") {
        if (
          (this.heatmap.application === "UX Design" ||
            this.heatmap.application === "Social media" ||
            this.heatmap.application === "(Digital) Out Of Home" ||
            this.heatmap.application === "Display Ads") &&
          this.workspace.access &&
          this.workspace.access.includes("audits")
        ) {
          val = val.concat(" 1fr");
        }
      }

      return val;
    },
    ...mapState({
      workspace: (state) => state.workspace.workspace,
    }),
  },
};
</script>
